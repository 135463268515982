/* ==========================================================================
   Global setting and variables
   ========================================================================== */
//** Animation
//** =========  */

$standard-duration: 0.15s;

//** Colors
//** =========  */

// base colors
$color-fg: #FFFFFF; // font color, messenger background
$color-bg: #000000; // background color
$color-gold: #EEC126;
$color-silver: #BBBBBB;

// mood colors
$color-mood-1: #F1F1F1; // White Nav - Sub Nav BG, Chat-Text BG / Video-Chat Text BG
$color-mood-2: #888888; // Subtexts & Items, White Nav - Sub-Nav batch BG (inactive)
$color-mood-3: #3C3D3E; // Bar-Bottom & Dialog icon White-Nav, Categories&Filter, Gray Buttons
$color-mood-4: #262728; // White Nav - Text, Filter & Categorie opened - Mid-Lines
$color-mood-5: #1D1E1F; // Header Background, Container Block Background, Video/Image Player - Bar (60 %)
$color-mood-6: #151617; // White-Nav-Categories&Filter Background
$color-mood-7: #101112; // Page Body Background, Model Thumbnail - Info BG (80 %)
$color-mood-8: #5f6164; // Page Footer Socialmedia-Icons and Subtext
$color-mood-9: #CCCCCC; // Subtext
$color-mood-10: #858585; // Subtext
$color-mood-11: #707070; // Avatar border

// highlight colors
$color-highlight: #FF4D3C; // Highlight Color
$color-highlight-special: #ebd730; // Special Yellow
$color-highlight-dark: #D34234; // Profile Phone BG
$color-highlight-active: #B5382C; // Button-Color Profile (active)
$color-highlight-inactive: #5D0A01; // Main-Nav / Sub-Nav (Inactive Textcolor), Profile-Case (Offline / Phone) Text
$color-highlight-gold: #C5A555; // Gold on advent calendar

// state colors
$color-success: #43B31C; // Success Color, Buttons & Icons & Text, Online Status
$color-special: #1F93E9; // Special Color, Buttons
$color-special-light: #5FB6F6; // Special Color, Teaser

// button colors
$color-button-success: #43B31C;
$color-button-special: #1F93E9;
$color-button-default: #3C3D3E;
$color-button-disabled: #888888;
$color-button-footer: #151617;

// button sizes
$font-size-button-sm:      1.0rem;
$font-size-button-md:      1.4rem;
$font-size-button-default: 1.6rem;
$font-size-button-lg:      2rem;
$font-size-button-xl:      2.4rem;
$font-size-button-xxl:     3.6rem;

// dildocontrol
$color-dildocontrol-main: #FE4584;
$color-dildocontrol-gradient-end: #DF359C;

// grid    (mirrored in Flux.Constants.Grid2.BREAKPOINT_MIN_WIDTHS)
$grid-min-width-xs: 400px;
$grid-min-width-sm: 768px;
$grid-min-width-md: 992px;
$grid-min-width-lg: 1200px;
$grid-min-width-xl: 1368px;
$breakpoint-sidebar-not-overlapped: 1470px;
$breakpoint-menu-expanded: $grid-min-width-lg;
$breakpoint-height-last: 750px;
$breakpoint-height-font-size: 800px;
$breakpoint-tablet-height: 850px;

$grid-breakpoints: sm $grid-min-width-sm, md $grid-min-width-md, lg $grid-min-width-lg, xl $grid-min-width-xl;

// SocialShareBar colors
$social-share-tab-background-color: #0B0B0B;
$social-share-tab-color: #636363;
$social-share-tab-active-background-color: #1D1E20;
$social-share-tab-active-color: #FFFFFF;
$social-share-select-box-color: #B0B0B0;
$social-share-textarea-background-color: #363636;

// Sharebuttons colors
$social-share-button-facebook: #3A579A;
$social-share-button-twitter: #00ABF0;
$social-share-button-google: #DF4A32;
$social-share-button-tumblr: #32506D;
$social-share-button-blogger: #F59038;
$social-share-button-reddit: #fC461E;

//** Mobile
//** ================ */
$mobile-breakpoint-phone-xxxl: 850px;
$mobile-breakpoint-phone-xxl: 700px;
$mobile-breakpoint-phone-xl: 600px;
$mobile-breakpoint-phone-big: 500px;
$mobile-breakpoint-phone-medium: 400px;
$mobile-breakpoint-phone-small: 350px;
$mobile-content-padding: 13px;
$mobile-guest-sub-menu-padding: 15px;
$mobile-navbar-height: 50px;

//** Fonts attributes
//** ================ */

//* Base
$font-family-base: 'Roboto', Tahoma, sans-serif;
$font-family-base-condensed: "Roboto Condensed", sans-serif;
$font-family-night: "Night Wind", sans-serif;
$font-family-icon: 'vx_iconfont';
$font-family-icon-actor: 'vx_iconfont_actor';
$font-family-handwriting: 'Sacramento', sans-serif;
$font-family-categories: $font-family-handwriting;
$font-family-lora: 'Lora';
$font-family-special: 'Night Wind', sans-serif;
$font-family-satisfy: 'Satisfy Regular';
$font-size-badges: 12px;
$font-size-master: 10px;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: 800;

$font-size-prosearach-categories: 1rem;
$font-size-label: 1.1rem;
$font-size-base: 1rem;
$font-size-sm: 1.0rem;
$font-size-md: 1.4rem;
$font-size-lg: 2.4rem;
$font-size-xl: 3.0rem;
$font-size-xxl: 3.0rem;
$font-size-365-title: 5.4rem;
$font-size-link-md: 1.4rem;
$font-size-age: 1.3rem;
$font-size-prosearch-legend: 1.7rem;
$font-size-prosearch-labels: 1.5rem;
$font-size-actor-show-text: 1.6rem;
$font-main-navi: 2.2rem;
$font-contents-navi: 1.6rem;
$font-sub-navi: 1.6rem;
$font-mobile-navi: 1.8rem;
$font-size-tile: 1.7rem;
$font-size-catchline: 4rem;
$font-size-profile-text: 1.5rem;
$font-size-separator-tile: 2.5rem;
$font-size-tablet-transition: 1.2rem;
$font-size-icon-standard: 1.8rem;

$magazine-content-padding: 30px;
$magazine-content-container: 1300px;
$magazine-box-offset: 220px;

// Header
$header-height: 128px;
$header-height--no-margin: 120px;
$header-height--small: 60px;
$header-height--small--no-margin: 52px;

// Display Solutions
$display-width-1024: 1024px;
// Desktop
$display-width-1260: 1260px;
$display-width-1366: 1366px;
$display-width-1440: 1440px;
$display-width-1600: 1600px;
$display-width-1920: 1920px;