@import "../Css/index.scss";

* {
	box-sizing: border-box;
	padding:    0;
	margin:     0;
}

.premium {
	background-position: top;
	background-repeat:   no-repeat;
	background-size:     100% auto;
	font-family:         $font-family-base;
	font-weight:         $font-weight-normal;
	.light &{
		color: $color-bg;
		background-color: $color-fg;
	}
	
	.dark &{
		color: $color-fg;
		background-color: $color-darker;
	}
	min-height:          100vh;
}

.premium__wrapper {
	max-width: 1200px;
	margin:    0 auto;
}

.content__wrapper--premium {
	padding-right: 25px;
	padding-left:  25px;

	@media (min-width: 992px) {
		margin-left:   auto;
		margin-right:  auto;
		max-width:     992px;
		padding-left:  0;
		padding-right: 0;
	}
}

.header {
	flex-direction: column;
	padding-bottom: 25px;
	align-items:    center;
	padding-top:    10px;
	display:        flex;

	@media (min-width: 992px) {
		margin-left:   auto;
		margin-right:  auto;
		max-width:     992px;
		padding-left:  0;
		padding-right: 0;
	}
}

.header__image {
	margin-bottom: 20px;
	max-width:     150px;
	height:        auto;
}

.header__text {
	font-family:    $font-family-base-condensed;
	font-weight:    $font-weight-bold;
	font-size:      25px;
	.light &{
		color: $color-bg;
	}
	
	.dark &{
		color: $color-fg;
	}
	text-transform: uppercase;

}

@media (min-width: 768px) {
	.header__text {
		font-size: 54px;
	}
}

.header__text--highlight {
	.light &{
		color: $color-bg;
	}

	.dark &{
		color: $color-lightblue;
	}
}

.abo-tile__container {
	display:               grid;
	grid-template-columns: 1fr;
	justify-content:       center;
}

.abo-tile__container--hide {
	transition: max-height 0.5s ease-in-out;
	max-height: 0;
	overflow:   hidden;
}

.abo-tile {
	grid-template-columns: 245px;
	padding-bottom:        50px;
	display:               grid;
	position:              relative;
	justify-content:       center;
}

.abo-tile__banner {
	width:            100%;
	padding:          5px;
	background-color: $color-dark;
	color:            $color-fg;
	position:         absolute;
	left:             0;
	top:              0;
	z-index:          1;
	display:          flex;
	justify-content:  flex-start;
	align-items:      center;
	font-size:        13px;

	& i {
		background-color: white;
	}
}

.abo-tile__image-wrapper {
	position:        relative;
	display:         flex;
	justify-content: center;
}

.premium--user .abo-tile__image-wrapper {
	border-bottom: 7px solid $color-lightblue;
}

.abo-tile__image-badge {
	justify-content: center;
	flex-direction:  column;
	align-items:     center;
	position:        absolute;
	display:         flex;
	height:          100px;
	color:           $color-fg;
	width:           100px;
	right:           15px;
	top:             15px;
}

.abo-tile__image-badge__background {
	background-repeat: no-repeat;
	background-image:  url("../images/defaultbadge.svg");
	background-size:   contain;
	position:          absolute;
	height:            100%;
	width:             100%;
}


.abo-tile__image-badge__text-wrapper {
	text-align: end;
	z-index:    1;
}

.abo-tile__image-badge__price {
	font-weight: $font-weight-bold;
	font-size:   19px;
}

.abo-tile__image-badge__period {
	font-size: 11px;
}

.abo-tile__image {
	width:               100%;
	height:              245px;
	background-repeat:   no-repeat;
	background-position: center;
	background-size:     cover;
	background-color:    $color-grey;
	place-items:         center;
	display:             grid;
	background-image:    url("../images/placeholder.png");
	padding-bottom:      100%;
}

.abo-tile__info-wrapper {
	flex-direction:  column;
	align-items:     center;
	padding:         0 15px 10px;
	display:         flex;
	justify-content: space-between;

}

.abo-tile__text-wrapper {
	display:        flex;
	flex-direction: column;
	align-items:    flex-start;
	width:          100%;
	align-items:    center;
}

.premium--user .abo-tile__info-wrapper {
	.light &{
		background-color: $color-lightgrey2;
	}

	.dark &{
		background-color: $color-dark;
	}
}


.abo-tile__headline {
	text-transform: uppercase;
	font-weight:    $font-weight-bold;
	font-family:    $font-family-base-condensed;
	margin-top:     30px;
	font-size:      25px;
	text-align:     center;
}

.abo-tile__subline {
	line-height: 26px;
	margin-top:  10px;
	font-size:   20px;
}

.abo-tile__text {
	line-height: 21px;
	font-size:   16px;
	margin:      25px 0;
	text-align:  center;
}

.abo-tile__text--discount {
	margin-left: 7px;
	color:       $color-blue;
}

.abo-tile__text--payment {
	color: $color-blue;
}

.abo-tile__text--abo-info {
	margin:     5px 0 30px;
	text-align: left;
}

.abo-tile__button {
	background-color: $color-blue;
	border-radius:    2px;
	padding:          10px 30px;
	cursor:           pointer;
	color:            $color-fg;
	margin-bottom:    10px;
}

.abo-tile__button:hover {
	background-color: $color-lightblue;
}

.abo-tile__ready_id {
	margin-top: 5px;
	font-size:  12px;
}

.abo-tile__button-container {
	margin-top:      15px;
	display:         flex;
	align-items:     center;
	flex-wrap:       wrap;
	justify-content: center;
	flex-direction:  column;

}

.abo-tile__button-info {
	font-size: 13px;
}

.abo-tile__button--disabled {
	background-color: $color-lightgrey;
	color:            black;
	cursor:           not-allowed;
}

.dark .abo-tile__button--disabled {
	background-color: $color-lightgrey2;
}

.abo-tile__button--disabled:hover {
	background-color: $color-grey;
}

.abo-tile__more-btn {
	justify-content: center;
	margin-bottom:   50px;
	align-items:     center;
	display:         flex;
	cursor:          pointer;
}

.abo-tile__more-btn__arrow {
	.light &{
		background-color: $color-bg;
	}
	
	.dark &{
		background-color: $color-fg;
	}
	mask-image:       url("../images/arrow-down--black.svg");
	transition:       transform 0.5s ease;
	transform:        rotateZ(0deg);
	mask-size:        contain;
	display:          inline-block;
	height:           30px;
	width:            30px;
}

.abo-tile__more-btn__arrow--rotate {
	transition: transform 0.5s ease;
	transform:  rotateZ(-180deg);
}

.expectation-tile {
	background-color: $color-lightgrey2;
	flex-direction:   column;
	padding-bottom:   50px;
	padding-right:    15px;
	padding-left:     15px;
	padding-top:      50px;
	align-items:      center;
	border-top:       7px solid $color-lightblue;
	display:          flex;
	margin:           25px auto;
	color:            $color-bg;
}

.expectation-tile__headline {
	text-transform: uppercase;
	line-height:    39px;
	font-family:    $font-family-base-condensed;
	font-size:      30px;
}

.expectation-tile__list {
	flex-direction: column;
	margin-top:     20px;
	list-style:     none;
	text-align:     left;
	max-width:      992px;
	display:        flex;
}

.expectation-tile__list-item {
	padding-left: 25px;
	position:     relative;
	display:      inline-block;
	margin:       5px 0;
}

.expectation-tile__list-item:before {
	background: url('../images/arrow-right--blue.svg');
	transform:  translateY(-2px);
	position:   absolute;
	content:    "";
	height:     25px;
	width:      25px;
	left:       0;
}

.unsubscribe-modal__wrapper {
	display:          flex;
	justify-content:  center;
	align-items:      center;
	position:         fixed;
	width:            100%;
	height:           100%;
	top:              0;
	left:             0;
	background-color: $color-grey;
	z-index:          2;
}

.dark .unsubscribe-modal__wrapper {
	background-color: $color-light-darker;
}

.unsubscribe-modal {
	width:            100vh;
	min-height:       100vh;
	.light &{
		color: $color-bg;
		background-color: $color-fg;
	}
	
	.dark &{
		color: $color-fg;
		background-color: $color-darker;
	}
	position:         relative;

	@media (min-width: 992px) {
		width:      50%;
		min-height: 500px;
	}
}

.unsubscribe-modal__close-btn {
	position: absolute;
	top:      5px;
	right:    5px;
}

.unsubscribe-modal__abo-container {
	display:               grid;
	grid-template-columns: auto 1fr;
}

.unsubscribe-modal__abo-container__img {
	height:              100%;
	width:               100px;
	display:             grid;
	place-items:         center;
	background-repeat:   no-repeat;
	background-size:     cover;
	background-color:    $color-grey;
	background-position: center;
}

.premium--user .unsubscribe-modal__abo-container__img {
	border-right: 5px solid $color-lightblue;
}

.unsubscribe-modal__abo-container__text {
	padding:          15px;
	.light &{
		background-color: $color-lightgrey2;
	}

	.dark &{
		background-color: $color-dark;
	}
}

.unsubscribe-modal__abo-container__text-headline {
	font-weight: 800;
	width:       100%;
	text-align:  left;
	font-size:   16px;
	.light &{
		color: $color-bg;
	}
	
	.dark &{
		color: $color-fg;
	}
}

.unsubscribe-modal__abo-container__text-subline {
	width:           100%;
	display:         flex;
	flex-direction:  column;
	justify-content: space-between;
	text-align:      left;
	margin-top:      5px;
	font-size:       13px;
	.light &{
		color: $color-bg;
	}
	
	.dark &{
		color: $color-fg;
	}
}

.unsubscribe-modal__text-container {
	display:        flex;
	flex-direction: column;
	margin-top:     100px;
	align-items:    center;
	padding:        0 10px;
}

.unsubscribe-modal__text-container--confirmation {
	padding: 0 20px 20px;
	text-align: left;
}

.unsubscribe-modal__text-container__headline {
	font-size: 25px;
}

.unsubscribe-modal__text-container__subline {
	font-size:     16px;
	color:         $color-grey;
	margin-top:    15px;
	margin-bottom: 75px;
}

.unsubscribe-modal__text-container__footer {
	text-align: left;
	margin-top: 20px;
}

.unsubscribe-modal__text-container__footer-headline {
	font-weight: bold;
}

.unsubscribe-modal__text-container__footer-subline {
	margin-top: 5px;
}

.unsubscribe-modal__text-container__subline--confirmation {
	margin-bottom: 0;
}

.unsubscribe-modal__text-container__cancel {
	font-size:       16px;
	color:           $color-grey;
	text-decoration: underline;
	margin-top:      15px;
	cursor:          pointer;
}

.abo-tile__no-abo {
	height:          60vh;
	display:         flex;
	justify-content: center;
	flex-direction:  column;
	align-items:     center;
}

.abo-tile__no-abo__headline {
	font-size:   30px;
	font-weight: 800;
}

.abo-tile__no-abo__subline {
	font-size:  18px;
	margin-top: 10px;
	color:      $color-blue;
	display:    flex;
}

/*** MEDIUM ***/
@media (min-width: 580px) {
	.abo-tile__container.abo-tile__container--multiple {
		grid-template-columns: repeat(auto-fit, minmax(245px, 1fr));
		grid-gap:              10px;
	}

	.abo-tile__container .abo-tile {
		grid-template-columns: 245px 245px;
		grid-gap:              50px;
		grid-template-rows:    auto 1fr;
	}

	.abo-tile__container.abo-tile__container--multiple .abo-tile {
		grid-template-columns: 245px;
		grid-gap:              0;
	}

	.abo-tile__container.abo-tile__container--multiple .abo-tile.content__wrapper {
		padding-right: 0;
		padding-left:  0;
	}
}

/*** LARGE ***/
@media (min-width: 992px) {
	.content__wrapper {
		padding-right: 0;
		padding-left:  0;
	}

	.abo-tile__container {
		max-width: 992px;
		margin:    0 auto;
	}

	.abo-tile__container.abo-tile__container--full-width {
		max-width: none;
	}

	.expectation-tile {
		max-width: 992px;
	}
}