/* Roboto */
@font-face {
	font-family: "Roboto";
	src: url("../fonts/Roboto-Light.woff2") format('woff2'), url("../fonts/Roboto-Light.woff") format('woff');
	font-weight: 300;
	font-style: normal;
  }
 
@font-face {
	font-family: "Roboto";
	src: url("../fonts/Roboto-LightItalic.woff2") format('woff2'), url("../fonts/Roboto-LightItalic.woff") format('woff');
	font-weight: 300;
	font-style: italic;
}
 
@font-face {
	font-family: "Roboto";
	src: url("../fonts/Roboto-Regular.woff2") format('woff2'), url("../fonts/Roboto-Regular.woff") format('woff'), url("../fonts/Roboto-Regular.ttf") format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Roboto";
	src: url("../fonts/Roboto-Italic.woff2") format('woff2'), url("../fonts/Roboto-Italic.woff") format('woff');
	font-weight: 400;
	font-style: italic;
}
 
@font-face {
	font-family: "Roboto";
	src: url("../fonts/Roboto-Bold.woff2") format('woff2'), url("../fonts/Roboto-Bold.woff") format('woff');
	font-weight: 700;
	font-style: normal;
  }

  @font-face {
	font-family: "Roboto";
	src: url("../fonts/Roboto-BoldItalic.woff2") format('woff2'), url("../fonts/Roboto-BoldItalic.woff") format('woff');
	font-weight: 700;
	font-style: italic;
  }
 
  
  /* Roboto Condensed */
  
  @font-face {
	font-family: "Roboto Condensed";
	src: url('../fonts/RobotoCondensed-Light.woff2') format('woff2'), url('../fonts/RobotoCondensed-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
  }

  @font-face {
	font-family: "Roboto Condensed";
	src: url('../fonts/RobotoCondensed-Regular.woff2') format('woff2'), url('../fonts/RobotoCondensed-Regular.woff') format('woff'), url("../fonts/RobotoCondensed-Regular.ttf") format('truetype');
	font-weight: 400;
	font-style: normal;
  }

  @font-face {
	font-family: "Roboto Condensed";
	src: url('../fonts/RobotoCondensed-Bold.woff2') format('woff2'), url('../fonts/RobotoCondensed-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
  }

 
  /* Night Wind */
  
  @font-face {
	font-family: "Night Wind";
	src: url('../fonts/NightWind.woff') format('woff'), url('../fonts/NightWind.ttf') format('ttf');
	font-weight: 300;
	font-style: normal;
  }
 