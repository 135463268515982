@import "../Css/index.scss";

$color-tip-gray: #666;

.tipping {
    padding-bottom: 100px;

    &.tipping--no-padding {
        padding: 0;
    }

    @media (min-width: $grid-min-width-sm) {
        padding: 0;
    }
}

.tipping__logo {
    position: absolute;
    top: 50px;
    margin: 0 20px;
    left: 0;
    max-width: 350px;

    @media (min-width: $grid-min-width-sm) {
        left: 20px;
        margin: 0;
    }
}

.tipping__image {
    width: 100%;
    padding-top: 150px;
    background-position: center;
    background-size: cover;
    @media (min-width: $grid-min-width-sm) {
        padding-top: 300px;
        padding-bottom: 50px;
    }
    @media (min-width: $grid-min-width-md) {
        padding-top: 400px;
    }
}

.tip {
    width: 100%;
    padding: 40px 20px 60px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0 auto;
    
    .dark &{
        background-color: transparent;
        color: $color-fg;
    }
    
    .light &{
        color: $color-bg;
        background-color: $color-fg;
    }

    @media (min-width: $grid-min-width-sm) {
        max-width: 350px;
    }
}

.tip--no-padding {
    padding: 40px 20px 1px;

    @media (min-width: $grid-min-width-md) {
        padding: 40px 20px 60px;
    }
}

.tip__headline {
    font-size: 24px;
    font-weight: $font-weight-bold;

    @media (min-width: $grid-min-width-md) {
        font-size: 30px;
    }

    .dark &{
        color: $color-fg;
    }
    
    .light &{
        color: $color-bg;
    }
}

.tip__text {
    font-size: 15px;
    color: $color-tip-gray;
    text-align: center;
    margin-top: 20px;
    max-width: 430px;

    .dark &{
        color: $color-fg;
    }
    
    @media (min-width: $grid-min-width-md) {
        font-size: 18px;
    }
}

.tip__input-container {
    width: 100%;
    max-width: 300px;
    margin-top: 40px;
    position: relative;
}

.tip__spinner-wrapper {
    display: grid;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;

    .spinner {
        padding: 0;
        margin:  0;
    }

    .dark &{
        background-color: $color-darker;
    }    
}

.tip__input-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $color-mood-2;
    border-radius: 5px;
    color: $color-mood-9;
    height: 40px;
    margin-top: 20px;
    position: relative;
    font-size: 17px;

    & .tip__input,
    &.tip__input-wrapper--active {
        color: $color-bg;

        .dark &{
            color: $color-fg;
        }
    }

    &.error {
        border-color: $color-highlight;
    }
    
    &.success {
        border-color: $color-success;
    }
}

.tip__input {
    width: 4ch;
    pointer-events: none;
    background-color: transparent;
    border: none;
    height: 100%;
    color: $color-mood-9;
    display: none;
    font-size: 17px;
    text-align: right;
    margin-right: 5px;
    font-family: $font-family-base;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    &[type=number] {
    -moz-appearance: textfield;
    }

    &::placeholder {
        color: $color-mood-9;
    }

    &:focus {
        border: none;
        outline: none;
    }

    &.tip__input--show {
        display: block;
    }
}

.tip__input-message {
    position: absolute;
    bottom: -20px;
    font-size: 12px;
    left: 0;    
    display: none;

    &.error {
        display: block;
        color: $color-highlight;
    }
}

.tip__input-icon {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

.tip__buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
}

.tip__button-wrapper {
    position: fixed;
    bottom:   0;
    left:     0;
    width:    100%;
    min-height:   65px;
    background-color: $color-fg;
    padding: 5px 5px 5px 5px;
    
    @media (min-width: $grid-min-width-sm) {
        margin-top: 30px;
        position: relative;
        width: 335px;
        padding: 0;
        height: 55px;
        min-height: 55px;
    }
}

.tip__button-tip {
    width: 100%;
    height: 40px;
    padding: 5px;
    border: 1px solid $color-mood-2;
    border-radius: 5px;
    color: $color-mood-9;
    background-color: transparent;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: env(safe-area-inset-bottom);

    &.tip__button-tip--active {
        background-color: $color-button-special;
        color: $color-fg;
        border: none;
    }
}

.tip__button {
    width: 100%;
    height: 65px;
    background-color: $color-button-special;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: $color-fg;
    font-size:  16px;
    cursor: pointer;
    z-index: 1;
    transition: color 0.01s; //workaround for safari button color change
    position: relative;

    @media (min-width: $grid-min-width-sm) {
        height: 55px;
    }
       

    &:disabled{
        background-color: #E1E4E5 ;
        color: $color-bg;
        pointer-events: none;
        cursor: no-drop;
    }
}


.tip__button-arrow {
    position:  absolute;
    right:     10px;
    top:       50%;
    transform: translateY(-50%);
    height:    40px;
    width:     auto;
}

.tip__avs-ready {
    margin-top: 10px;
    color: black;

    @media (min-width: $grid-min-width-sm) {
        color: white;

        .light & {
            color: black;
        }
    }
}

.tip__change-payment {
    margin-top: 15px;
    cursor: pointer;
}

.tip__change-payment--mobile {
    display: block;
    margin-top: 40px;
    @media (min-width: $grid-min-width-sm) {
        display: none;
    }
}

.tip__change-payment--desktop {
    display: none;
    @media (min-width: $grid-min-width-sm) {
        display: block;
    }
}

.benefits__container {
    margin-top:  60px;
    width:       100%;
    padding:     30px 0;
    background:  linear-gradient(to bottom, #9672FB, #179CDE);
    display:     flex;
    flex-direction: column;
    align-items: center;
} 

.benefits__headline {
    font-weight: $font-weight-bold;
    font-size: 24px;
    color: $color-fg;

    @media (min-width: $grid-min-width-md) {
        font-size: 30px;
    }
}

.benefits__text {
    font-size: 15px;
    color: $color-fg;
    margin-top: 20px;
    max-width: 320px;
        
    @media (min-width: $grid-min-width-sm) {
        max-width: 450px;
    }
    
    @media (min-width: $grid-min-width-md) {
        max-width: 560px;
        font-size: 18px;
    }
}

.benefits__button {
    width: 166px;
    height: 40px;
    color: $color-fg;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $color-fg;
    margin: 30px 0 20px 0;
    cursor: pointer;
}

.ranking {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px 30px;
    align-items: center;
    margin: 20px auto 0;

    @media (min-width: $grid-min-width-sm) {
        max-width: 390px;
        padding: 40px 0;
    }
    
    .dark &{
        color: $color-fg;
    }
    
    .light &{
        color: $color-bg;
    }
    
    &.ranking--succes {
        margin-top: 50px;
        background-color: #ECF7E7;
        color: $color-bg;
    }
}

.ranking__headline {
    font-weight: $font-weight-bold;
    font-size: 24px;
    text-align: center;
    
    @media (min-width: $grid-min-width-sm) {
        font-size: 30px;
    }
}

.ranking__info {
    font-size: 15px;
    color: $color-tip-gray;
    margin-top: 20px;
    width: 260px;

    &.ranking__info--center {
        align-items: center;
        margin: 20px auto 0;
    }    

    @media (min-width: $grid-min-width-sm) {
        font-size: 18px;
    }

    @media (min-width: $grid-min-width-md) {
        width: auto;
    }
}

.ranking__update {
    font-weight: $font-weight-bold;
    font-size: 15px;
    margin-top: 10px;
}

.ranking__update-badge {
    background-color: #B3E1A4;
    border-radius: 10px;
    font-size: 10px;
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 0 5px;
    margin-top: 20px;

    & span {
        margin-right: 5px;
    }

    &.ranking__update-badge--list {
        margin: 0;
        .dark &{
            background-color: $color-fg;
            color: $color-bg;
        }
    }

    @media (min-width: $grid-min-width-md) {
        font-size: 14px;
    }
}

.ranking__selector {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 10px;
}

.ranking__selector__item {
    font-size: 15px;
    color: $color-mood-2;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 80px;

    .dark &{
        color: $color-mood-9;
    }

    &.ranking__selector__item--active {
        color: $color-special;
        border-bottom: 2px solid $color-special;
    }
}

.ranking__list {
    width: 100%;
}

.ranking__list--margin {
    margin-top: 30px;
}

.ranking__list-item {
    padding: 10px;
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 1px;
    height: 60px;
    
    @media (min-width: $grid-min-width-md) {
        height: 76px;
    }

    .dark &{
        background-color: $color-mood-3;
    }

    &:nth-child(odd) {
        background-color: #F2F2F2;

        .dark &{
            background-color: $color-mood-3;
        }
    }

    &.ranking__list-item--distance {
        flex-direction: column;
        color: $color-mood-9;
        line-height: 10px;
        font-size: 20px;
        justify-content: center;

        .dark &{
            background-color: transparent;
        }
    }
}

.ranking__list-item__rank {
    text-align: left;
    width: 60px;
    font-size: 15px;
    
    @media (min-width: $grid-min-width-md) {
        font-size: 18px;
    }
}

.ranking__list-item__image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background:  linear-gradient(to bottom, #33BEF2, #332EC4);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: $font-weight-bold;

    .dark &{
        color: $color-bg;
    }
}

.ranking__list-item__name-container {
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.ranking__list-item__name {
    font-size: 15px;

    @media (min-width: $grid-min-width-md) {
        font-size: 20px;
    }
}

.ranking__list-item__badge {
    margin-top: 2px;
}

.ranking__slogan {
    width: 100%;
    text-align: center;
    color: $color-tip-gray;
    font-size: 15px;
    margin-top: 30px;

    .dark & {
        color: $color-fg;
    }
}

.tipping__footer {
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    margin: 60px auto 0; 
    width: fit-content;
}

.tipping__footer-support {
    font-size: 13px;

    .dark & {
        color: #E1E4E5;
    }

    span {
        color: $color-special;
    }
}

.footer-meta__wrapper {
    background-color: #F2F2F2;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    
    .dark & {
        background-color: $color-mood-3;
    }
    
    .footer__meta {
        .dark & {
            .footer__meta-item{
                color: $color-fg;
            }
        }
        margin: 0;
    }
}

.tip__modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:1;
}

.tip__modal {
    background-color: $color-fg;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    color: $color-bg;
    position: relative;
    overflow: hidden;
    
    @media (min-width: $grid-min-width-md) {
        height: 600px;
        width:  635px;
        font-size: 20px;
    }
}

.tip__modal-confirmation {
    background-color: #ECF7E7;
    font-size: 13px;
    text-align: center;
    height: 45px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translateY(-100%);
    transition: 0.5s ease-in-out transform;

    @media (min-width: $grid-min-width-md) {
        position: fixed;
        top:0;
        left: 0;
        font-size: 18px;
    }
}

.tip__modal-confirmation--active {
    transform: translateY(0);
    transition: 0.5s ease-in-out transform;
}

.tip__modal-confirmation__icon {
    height: 15px;
    margin-right: 5px;

    @media (min-width: $grid-min-width-md) {
        margin-right: 10px;
        height: 20px;
    }
}

.tip__modal-headline {
    font-size: 24px;
    font-weight: $font-weight-bold;
    color: $color-bg;
    margin-top: 130px;

    @media (min-width: $grid-min-width-md) {
        font-size: 30px;
    }
}

.tip__modal-text {
    color: $color-tip-gray;
    font-size: 15px;
    margin-top: 20px;
    width: 220px;

    @media (min-width: $grid-min-width-md) {
        font-size: 18px;
        width: 300px;
    }
}

.tip__modal-pin {
    font-size: 60px;
    line-height: 45px;
    margin-top: 45px;
    letter-spacing: 10px;
}

.tip__modal-copy {
    color: $color-special;
    font-size: 15px;
    margin-top: 15px;
    cursor: pointer;
}