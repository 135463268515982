@import "../Css/index.scss";

.login-container {
    display:        flex;
    flex-direction: column;
    padding:        0 10px;
    max-width:      400px;
    margin:         0 auto;
    min-height:      100vh;
    justify-content: space-between;
    padding-bottom:  30px;
    position:        relative;
}

.login-container.login-container--align-center {
    height:          100vh;
    justify-content: center;
    align-items:     center;
}

.login-container__message {
    position:  fixed;
    transform: translateY(-100%);
    color:     $color-grey;
    padding:   20px 0;
    transition: all 0.75s ease;
    width:      100%;
    z-index:    10;
    left:       0;
    background-color: $color-fg;
}

.login-container__message--error {
    color:     $color-red;
}

.login-container__message--show {
    transform: translateY(0);
    transition: all 0.75s ease;
    box-shadow: 0px 0px 15px $color-grey;
}

.form {
    width: 100%;
    padding: 0 30px;
}

@media (min-width: 768px) {
    .form {
        padding: 0;
    }
}

.form-group {
    position:              relative;
    margin-top:            7vh;
    display:               flex;
    flex-direction:        column;
    color:                 $color-dark;
    
    .dark & {
        color: white;
    }
}

.form--space-between,
.form-group--space-between {
    width:           100%;
    justify-content: space-between;
    display:         flex;
}

.form-group--row {
    flex-direction: row;
    align-items: center;
}

.form-group--block {
    display: block;
}

.form-group--mg {
    margin-top: 3vh;
}

.form-group--left {
    text-align: left;
}

.form-group__headline {
    font-family: $font-family-base-condensed;
    font-weight: $font-weight-bold;
    font-size:   25px;
    .light &{
		color: $color-bg;
	}
	
	.dark &{
		color: $color-fg;
	}
    text-transform: uppercase;
}

.form-group__recaptcha {
    display:         flex;
    justify-content: center;
    align-items:     center;
}

.form-group__headline span {
    color: $color-gold;
}

.form-group__label {
    .light &{
		color: $color-bg;
	}
	
	.dark &{
		color: $color-fg;
	}
    font-size:   16px;
    font-weight: $font-weight-light;
    transform:   translateY(8px);
    transition:  0.5s ease all; 
    pointer-events: none;
    margin-left: 10px;
    position: absolute;
    top: 5px;
    
}

.form-group__label--move-top {
    font-size:  11px;
    transform:  translateY(0);  
    transition: 0.5s ease all; 
}

.form-group__label-error {
    color:     $color-red;
    top:       50px;
    position:  absolute;
    opacity:   0;
}

.form-group__label-error--top {
    top: 80px;
}

.form-group__label-error--show {
    opacity: 1;
    transition: 0.3s ease all;
}

.form-group__input {
    border:           1px solid $color-lightgrey;
    border-radius:    2px;
    font-size:        16px;
	color:            $color-dark;
    background-color: $color-light;
    padding:          20px 10px 5px;
}

.dark .form-group__input {
    border:           1px solid $color-fg;
    color:            $color-fg;
    background-color: $color-darker;
}

.form-group__info {
    font-size:  12px;
    color:      $color-grey;
    text-align: left;
    display:    flex;
    align-items: flex-start;
    margin-top:  5px;
}

.form-group__input:focus {
    outline: none;
}

form input.form-group__input:focus + label.form-group__label {
    color: $color-blue;
}

.form-group__input--show-hide::after {
    content: "";
    width:   24px;
    height:  24px;
}

.form-group__password-toggle {
    position: absolute;
    width:    20px;
    height:   100%;
    right:    10px;
    display:  flex;
    align-items: center;
    cursor:   pointer;
}

.form-group__button {
    display:          inline-flex;
    padding:          20px 30px;
    border-radius:    5px;
    background-color: $color-blue;
    color:            $color-fg;
    margin-top:       70px;
    font-size:        16px;
    border:           none;
    align-items:      center;
}

.form-group__button--cancel {
    background-color: $color-red;
}

.form-group__button--disabled {
    background-color: $color-grey;
}

.form-group__button--success {
    background-color: $color-green;
}

.form-group__button i {
    background-color: $color-fg;
}

.form-group__link {
    color:      $color-grey;
    font-size:  16px;
    margin-top: 20px;
    cursor:     pointer;
}

.signup__link {
    font-size:   16px;
    font-weight: $font-weight-light;
    color:       $color-grey;
    margin-top:  20px;
    .light &{
		color: $color-bg;
	}
	
	.dark &{
		color: $color-fg;
	}
}

.signup__link--fixed {
    bottom:     50px;
    width:      100%;
    text-align: center;
    position:   absolute;
    margin-top: 0;
}

.signup__link--blue {
    cursor: pointer;
    color:  $color-blue;
}

.reset-info {
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}