@import "../Css/index.scss";

$color-blue:  #179CDE;
$color-purple: #9672FB;
$color-purple-blue: transparent linear-gradient(180deg, $color-purple 0%, $color-blue 100%) 0% 0%;

/*
@media (min-width: $grid-min-width-md) {

}
*/

$color-telegram-gray: #666;
$testimonials-height: 430px;

.landingpage {   
    padding: 0 30px;
    @media (min-width: $grid-min-width-sm) {
        margin:    0 auto;
        max-width: 992px;
    }
    
    @media (min-width: $display-width-1024) {
        padding:   0;
        max-width: 992px;
    }
}

.language-selector {
    height: 70px;
    width:  100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.languagel-selector__item {
    margin-left: 40px;
    font-size:   15px;
    font-weight: $font-weight-bold;
    cursor: pointer;
    color: $color-bg;
    
    &:first-child {
        margin-left: 0;
    }
    
    &.languagel-selector__item--active {
        color:           $color-blue;
        text-decoration: underline;
    }
}

.lp-padding-mobile {
    padding-left: 20px;
}

.lp-padding-tablet {
    @media (min-width: $grid-min-width-sm) {
        padding-left: 40px;
    }
}

.lp-padding-sm-md {
    @extend .lp-padding-mobile;
    @extend .lp-padding-tablet;
}

.lp__card {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;


    &.lp__card--margin {
        margin-top: 100px;
    }
    
}

.lp__card-item {
    display:        flex;
    flex-direction: column;
    align-items:    flex-start;
    text-align:     left;

    &.lp__card-item--left {
        align-items: flex-start;
        text-align:  left;
        padding-left: 40px;

        @media (min-width: $grid-min-width-md) {
            padding-left: 0;
        }
    }
    &.lp__card-item--right {
        align-items: flex-end;
    }
    
    &.lp__card-item--center {
        align-items:     center;
        justify-content: center;
    }
}

.lp-header {
    max-width: 100%;
}

.lp-header__mobile {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @media (min-width: $grid-min-width-sm) {
        display: none;
    } 
}

.lp-header__desktop{
    display: none;
    @media (min-width: $grid-min-width-sm) {
        display: block;
    } 
}

.lp-header__video-figure {
    position: relative;
}

.lp-header__video-caption {
    position:  absolute;
    bottom:    50px;
    left:      50px;
    max-width: 330px;
    text-align: left;
    transition: 0.5s ease-in-out;

    .lp-header--video-playing & {
        opacity: 0;
        transition: 0.5s ease-in-out;
    }
}

.lp-header__video-wrapper {
    position: relative;
}

.lp-header__video{
    width: 100%;
    height: auto;
}

.lp-header__image {
    width: 100%;
    height: auto;
    transition: 0.5s ease-in-out;

    @media (min-width: $grid-min-width-sm) {
        .lp-header--video-playing & {
            transform: translateY(110px);
            transition: 0.5s ease-in-out;
        }
    }

}

.lp-header__play {
    position: absolute;
    right:   50%;
    bottom:     50%;
    transform: translate(50%, 50%);
    width:    40px;
    height:   40px;
    cursor:   pointer;
    transition: 0.5s ease-in-out;

    @media (min-width: $grid-min-width-sm) {
        right: 25%;
    }

    @media (min-width: $display-width-1024) {
        width:  60px;
        height: 60px;
    }

    .lp-header--video-playing & {
        opacity: 0;
        cursor: none;
        pointer-events: none;
        transition: 0.5s ease-in-out;
    }
}

.lp-header__modelbot {
    width: 100%;
    height: auto;

    @media (min-width: $grid-min-width-sm) {
        transform: translateY(0);
        transition: 0.5s ease-in-out;

        .lp-header--video-playing & {
            transform: translateY(65px);
            transition: 0.5s ease-in-out;
        }
    }
 
    @media (min-width: $display-width-1024) {
        transform: translate(20px, 0);
        transition: 0.5s ease-in-out;

        .lp-header--video-playing & {
            transform: translate(20px, 120px);
            transition: 0.5s ease-in-out;
        }
    }
}

.lp-header__headline {
    font-weight: $font-weight-bold;
    fill:        $color-blue;
    
    @media (min-width: $grid-min-width-sm) {
        color:   white;
        font-size: 40px;
    } 

    @media (min-width: $display-width-1024) {
        font-size: 54px;
    } 
}

.lp-header__subline {
    font-family: $font-family-night;
    font-size:   30px;
    color:       $color-blue;
    margin-top:  5px;

    @media (min-width: $grid-min-width-sm) {
        text-align: left;
    } 
    
    @media (min-width: $display-width-1024) {
        font-size: 40px;
    } 
}

.lp-header__text {
    text-align: left;
    font-family: $font-family-base-condensed;
    font-style: italic;
    font-size:  18px;
    margin-top: 10px;

    @media (min-width: $grid-min-width-sm) {
        color:   white;
        margin-bottom: 20px;
    } 
}

.lp-header__list {
    list-style: none;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size:  15px;
    max-width:  320px;
    width:      100%;
    
    @media (min-width: $grid-min-width-sm) {
        justify-content: flex-start;
        max-width: 100%;
        margin-bottom: 145px;
        max-width:none;
    } 
    
    @media (min-width: $display-width-1024) {
        font-size: 22px;
        margin-bottom: 210px;
        justify-content: space-between;
        max-width: 420px;
    } 
}

.lp-header__list-item {
    font-style:  italic;
    font-weight: $font-weight-bold;
    
    @media (min-width: $grid-min-width-sm) {
        margin-right: 20px;
    } 
    
    @media (min-width: $display-width-1024) {
        margin-right: 0;
    } 

    span {
        color:        $color-blue;
        margin-right: 3px;
    }
}

.lp-button {
    background-color: $color-blue;
    padding:          15px 30px;
    display:          inline-block;
    color:          white;
    font-size:        16px;
    cursor:           pointer;
    @media (min-width: $grid-min-width-sm) {
        .lp-header--video-playing & {
            pointer-events: none;
        }
    } 
}

.lp-button__fixed-wrapper {
    position: fixed;
    bottom: 40px;
    width: 100%;
    height: auto;
    left:   0;
    z-index: 100;
}

.lp-header__grid {
    display: grid;
    grid-template-columns: auto 350px;
    align-items: flex-end;
    transform: translateY(-65px);
    pointer-events: none;
    
    @media (min-width: $display-width-1024) {
        grid-template-columns: auto 495px;
        transform: translateY(-120px);
    }
}

.lp-header__grid-left {
    position: relative;
}

.lp-header__paper {
    width: 160px;
    height: auto;
    position: absolute;
    right:    0;
    bottom:   0;
    
    @media (min-width: $display-width-1024) {
        right:    -50px;
        width: 250px;
    } 
}

.lp-header__monetizing-wrapper {
    @media (min-width: $grid-min-width-sm) {
        width: 100%;
        text-align: right;
    }
}

.lp-header__monetizing-messaging {
    font-size: 32px;
    font-weight: $font-weight-bold;
    color: $color-blue;
    opacity: 0.2;
    text-transform: uppercase;

    @media (min-width: $grid-min-width-sm) {
        font-size: 49px;        
    }

    @media (min-width: $display-width-1024) {
        font-size: 65px;        
    } 
}

.lp-header__monetizing {
    font-size: 50px;
    font-weight: $font-weight-bold;
    color: $color-bg;
    opacity: 0.2;
    text-transform: uppercase;
   
    @media (min-width: $grid-min-width-sm) {
        font-size: 76px;        
    }
    
    @media (min-width: $display-width-1024) {
        font-size: 100px;        
    } 
}

.lp-header__monetizing-level {
    font-size: 18px;
    font-weight: $font-weight-bold;
    color: $color-bg;
    text-transform: uppercase;

    @media (min-width: $grid-min-width-sm) {
        font-size: 20px;        
    }

    @media (min-width: $display-width-1024) {
        font-size: 26px;        
    } 
}

.lp-monetizing__bubbles {
    max-width: 400px;
    position: relative;
    @media (min-width: $grid-min-width-sm) {
        max-width: 610px;
    }
}

.lp-monetizing__bubble-icon--small {
    @media (min-width: $grid-min-width-sm) {
        display: none;   
    }
}

.lp-monetizing__bubble-icon--tablet {
    display: none;
    @media (min-width: $grid-min-width-sm) {
        display: block;   
    }
}

.lp-monetizing__bubble-colored__wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
} 

.lp-monetizing__bubble {
    box-shadow:   0px 1px 8px #00000029;
    font-size: 15px;
    position: relative;
    padding: 20px;
    display: inline-grid;
    border-radius: 10px;

    @media (min-width: $grid-min-width-sm) {
        line-height: 35px;
        font-size: 20px;
    }

}

.lp-monetizing__bubble-gray {
    background:  #F2F2F2;
    color: #707070;
    text-align: left;
}

.lp-monetizing__bubble-colored {
    color: white;
    background: transparent linear-gradient(91deg, #9672FB 0%, $color-blue 100%) 0% 0% no-repeat padding-box;
}

/*
* --- M E S S A G E S ---
*/

.lp-monetizing__bubbles-wrapper--messages {
    margin-top:     100px;

    .lp-monetizing__bubbles {
        .lp-monetizing__bubble-icon--tablet {
            width: 170px;
            height: auto;
            position: absolute;
            top: -40px;
            left: -20px;
            z-index: 1;
        }

        .lp-monetizing__bubble-gray {
            padding: 20px 20px 20px 60px;
            span {
                width: 215px;
                
                @media (min-width: $grid-min-width-sm) {
                    width: 420px;
                }
            }

            @media (min-width: $grid-min-width-sm) {
                padding: 20px 20px 20px 120px;
                margin-left: 40px;
            }

            .lp-monetizing__bubble-icon--small {
                width: 90px;
                height: auto;
                position: absolute;
                top: -40%;
                left: -30px;
            }
        }

        .lp-monetizing__bubble-colored {
            padding: 20px 10px 20px 20px;
            display: grid;
            grid-template-columns: auto auto;
            justify-content: flex-end;
            
            @media (min-width: $grid-min-width-sm) {
                width: 460px;
                padding: 20px 20px 20px 70px;
                transform: translateY(-10px);
            }

            span {
                text-align: left;
                width: 200px;

                @media (min-width: $grid-min-width-sm) {
                    width: auto;
                }
            }

            .lp-monetizing__bubble-icon {
                width: 80px;
                height: auto;
                position: relative;
                top:auto;
                left: auto;
                @media (min-width: $grid-min-width-sm) {
                    width: 120px;
                }
            }
        }
    }
}

/*
* --- M O N O T I Z E ---
*/

.lp-monetizing__bubbles-wrapper--monotize {
    margin-top:      175px;
    display:         flex;
    justify-content: flex-end;
    
    @media (min-width: $grid-min-width-sm) {
        margin-top: 250px;
    }

    .lp-monetizing__bubbles {
        .lp-monetizing__bubble-gray {
            padding: 40px 15px 20px 15px;
            transform: translateY(-20px); 
            span {
                max-width: 295px;
                @media (min-width: $grid-min-width-sm) {
                    max-width: 595px;
                }
            }
        }

        .lp-monetizing__bubble-gray__wrapper {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        } 

        .lp-monetizing__bubble-colored__wrapper {
            justify-content: flex-end;
        }

        .lp-monetizing__bubble-colored {
            padding: 20px 85px 20px 15px;
            display: grid;
            grid-template-columns: auto 1fr;
            z-index: 1;
            
            @media (min-width: $grid-min-width-sm) {
                padding: 20px 150px 20px 15px;
                margin-right: 60px;
            }

            span {
                text-align: left;
                width: 200px;
                
                @media (min-width: $grid-min-width-sm) {
                    width: 330px;
                }
            }

            .lp-monetizing__bubble-icon {
                height: 180px;
                width: auto;
                position: absolute;
                top: -125px;
                right: -10px;

                @media (min-width: $grid-min-width-sm) {
                    height: 325px;
                    top:auto;
                    bottom: -10px;
                    right: -50px;
                }
            }
        }
    }
}
/*
* --- B O T ---
*/

.lp-monetizing__bubbles-wrapper--bot {
    margin-top:      175px;

    @media (min-width: $grid-min-width-sm) {
        margin-top: 250px;
    }

    .lp-monetizing__bubbles {
        .lp-monetizing__bubble-gray {
            padding: 40px 80px 20px 35px;
            transform: translateY(-20px); 
            span {
                max-width: 200px;
                @media (min-width: $grid-min-width-sm) {
                    max-width: 260px;
                }
            }

            @media (min-width: $grid-min-width-sm) {
                padding: 40px 230px 20px 35px;

            }

            .lp-monetizing__bubble-icon--tablet {
                height: auto;
                width: 310px;
                position: absolute;
                bottom: -30px;
                right: -120px;
            }
        }

        .lp-monetizing__bubble-gray__wrapper {
            width: 100%;
            display: flex;
            justify-content: flex-start;

            @media (min-width: $grid-min-width-sm) {
                margin-left: 20px;
            }
        } 

        .lp-monetizing__bubble-colored__wrapper {
            justify-content: flex-end;
            
            @media (min-width: $grid-min-width-sm) {
                justify-content: flex-start;
            }
        }

        .lp-monetizing__bubble-colored {
            padding: 45px 80px 20px 30px;
            display: grid;
            grid-template-columns: auto 1fr;
            z-index: 1;
            text-transform: uppercase;
            
            @media (min-width: $grid-min-width-sm) {
                padding: 20px 30px 20px 30px;
                margin-right: 60px;
            }

            span {
                text-align: left;
                width: 200px;
            }

            .lp-monetizing__bubble-icon--small {
                height: 180px;
                width: auto;
                position: absolute;
                top: -130px;
                right: 0px;
            }
        }
    }
}

/*
* --- S O C I A L ---
*/

.lp-monetizing__bubbles-wrapper--social {
    margin-top:      120px;
    display:         flex;
    justify-content: flex-end;

    @media (min-width: $grid-min-width-md) {
        margin-top: 250px;
    }

    .lp-monetizing__bubble-icon--tablet {
        width: 225px;
        height: auto;
        position: absolute;
        top: -100px;
        right: -25px;
        z-index: 1;
    }

    .lp-monetizing__bubbles {
        .lp-monetizing__bubble-gray__wrapper {
            @media (min-width: $grid-min-width-sm) {
                display: flex;
                justify-content: flex-end;
            }
        }

        .lp-monetizing__bubble-gray {
            padding: 20px 110px 20px 20px;
            span {
                width: 200px;
                
                @media (min-width: $grid-min-width-sm) {
                    width: 430px;
                }
            }

            @media (min-width: $grid-min-width-sm) {
                padding: 20px 200px 30px 20px;
            }

            .lp-monetizing__bubble-icon--small {
                width: 115px;
                height: auto;
                position: absolute;
                top: -80px;
                right: 0px;
            }
        }

        .lp-monetizing__bubble-colored__wrapper {
            @media (min-width: $grid-min-width-sm) {
                justify-content: flex-end;
            }
        }
        
        .lp-monetizing__bubble-colored {
            padding: 20px 45px 20px 20px;
            display: grid;
            grid-template-columns: auto auto;
            justify-content: flex-end;
            margin-left: 15px;
            transform: translateY(-10px);
            text-transform: uppercase;
            
            @media (min-width: $grid-min-width-sm) {
                width: 480px;
                padding: 20px 50px 20px 20px;
                transform: translateY(-20px);
                margin-right: 70px;
                justify-content: flex-start;
                text-transform:unset;
            }

            span {
                text-align: left;
                width: 230px;
                
                @media (min-width: $grid-min-width-sm) {
                    width: 350px;
                }
            }
        }
    }
}

.lp-benefits {
    margin-top: 85px;
    display: grid;
    position: relative;

    @media (min-width: $grid-min-width-sm) {
        margin-top:  320px;
    }

}

.lp-benefits__headline {
    font-weight: $font-weight-bold;
    font-size:   50px;
    color:       $color-bg;
    opacity:     0.2;
    
    @media (min-width: $grid-min-width-sm) {
        font-size:  76px;
        text-align: left;
    }
    
    @media (min-width: $display-width-1024) {
        font-size:   100px;
    } 
}

.lp-benefits__list {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 20px;
    margin-top: 25px;
    
    @media (min-width: $grid-min-width-sm) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        position: absolute;
        width:    470px;
        top:       -150px;
        right: 0;
        margin-top: 0;
    }
    
    @media (min-width: $display-width-1024) {
        width: 620px;
        right: 30px;
        top:   -135px;
    } 
}

.lp-benefits__list-item {
    display: grid;
    justify-content: center;
    grid-row-gap: 10px;
    justify-items: center;
    color: #666666;
    font-weight: $font-weight-bold;
    grid-template-rows: auto 1fr;
    text-transform: uppercase;
    grid-template-columns: 100px;
    
    @media (min-width: $grid-min-width-sm) {
        font-size: 18px;
        grid-template-columns: 120px;
    }
    
    &.lp-benefits__list-item--dummy {
        display: none;
        @media (min-width: $grid-min-width-sm) {
            display: grid;
        }
    }
}

.lp-benefits__list-item__icon-wrapper {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.lp-benefits__list-item__icon-wrapper--blue {
        background-color: $color-blue;
    }
    
    &.lp-benefits__list-item__icon-wrapper--purple {
        background-color: $color-purple;
    }

    &.lp-benefits__list-item__icon-wrapper--purple-blue {
        background: $color-purple-blue;
    }
}

.lp-features {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    
    @media (min-width: $grid-min-width-sm) {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-areas: 'img headline' 'img list';
        margin-top: 185px;
        align-items: center;
    }

    @media (min-width: $display-width-1024) {
        grid-column-gap: 100px;
    } 
}

.lp-features__headline {
    color: $color-bg;
    font-weight: $font-weight-bold;
    font-size: 50px;
    opacity: 0.2;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    grid-area: headline;

    @media (min-width: $grid-min-width-sm) {
        font-size: 76px;
    }

    @media (min-width: $display-width-1024) {
        text-align: left;
    } 
}

.lp-features__headline--blue {
    color: $color-blue;
    font-size: 42px;

    @media (min-width: $grid-min-width-sm) {
        font-size:   63px;
        line-height: 40px;
    }
}

.lp-features__image {
    grid-area: img;
    width: 100%;
    height: auto;
    margin-top: 30px;
    max-width: 340px;
    
    @media (min-width: $display-width-1024) {
        max-width: 430px;
    } 
}

.lp-features__list {
    grid-area: list;
    list-style: none;
    max-width: 400px;
}

.lp-features__list-item {
    margin-top: 40px;

}

.lp-features__list-item__headline {
    color: $color-bg;
    font-weight: $font-weight-bold;
    font-style: italic;
    font-size: 26px;
    font-family: $font-family-base-condensed;
    text-align: left;
    
    span {
        color: $color-blue;
        margin-right: 5px;
    }
}

.lp-features__list-item__text {
    font-style: italic;
    font-size: 15px;
    margin-left: 15px;
    text-align: left;
    color: #666666;
    line-height: 24px;
}

.lp-manual {
    margin-top: 80px;

    @media (min-width: $grid-min-width-sm) {
       margin-top: 130px;
    }    

    @media (min-width: $display-width-1024) {
        margin-top: 255px;
    }
}

.lp-manual__headline {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    font-size: 36px;
    color: $color-blue;
    opacity: 0.2;

    @media (min-width: $grid-min-width-sm) {
        font-size: 76px;
    }    

    @media (min-width: $display-width-1024) {
        font-size: 100px;
    }

}

.lp-manual__grid {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 60px;
    justify-items: center;
    grid-template-areas: 'you' 'paper' 'fan';
    
    @media (min-width: $grid-min-width-sm) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 'you fan' 'paper fan';
        justify-items: flex-end;
        align-items: end;
    }
    
    @media (min-width: $display-width-1024) {
        grid-template-columns: 1fr auto 1fr;
        grid-template-areas: 'you paper fan';
        align-items: center;
    } 
}

.lp-manual__you-wrapper {
    display: flex;
    margin-left: 20px;
    justify-content: flex-end;
    grid-area: you;

    @media (min-width: $display-width-1024) {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
}

.lp-manual__you,
.lp-manual__fan {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 50px;
}

.lp-manual__you__headline,
.lp-manual__fan__headline {
    font-size: 26px;
    font-weight: $font-weight-bold;
    width: 100%;
    text-align: left;
}

.lp-manual__you__text,
.lp-manual__fan__text {
    font-size: 15px;
    margin: 10px 0 0 15px;
    font-style: italic;
    text-align: left;
    line-height: 24px;
}

.lp-manual__you {
    position: relative;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background: $color-purple-blue;
    color: white;

    &::before {
        content: '';
        width: 300px;
        height: 300px;
        border-radius: 50%;
        background-color: $color-blue;
        position: absolute;
        top:-20px;
        left: -20px;
        z-index: -1;
        opacity: 0.2;
    }

    @media (min-width: $grid-min-width-sm) {
        width: 315px;
        height: 315px;

        &::before {
            width: 315px;
            height: 315px;
        }
    }

    @media (min-width: $display-width-1024) {
        width: 350px;
        height: 350px;

        &::before {
            width: 350px;
            height: 350px;
        }
    } 
}

.lp-manual__paper {
    transform: scaleX(-1) rotate(-45deg);
    width: 180px;
    height: auto;
    grid-area: paper;

    @media (min-width: $grid-min-width-sm) {
        transform: scaleX(1) rotate(0);
    }
}

.lp-manual__fan-wrapper {
    grid-area: fan;
    @media (min-width: $grid-min-width-sm) {
        width:  100%;
        display: flex;
        justify-content: flex-start;
    }

    @media (min-width: $display-width-1024) {
        justify-content: flex-end;
    }

}

.lp-manual__fan {
    width: 330px;
    height: 330px;
    border-radius: 50%;
    box-shadow: 0px 10px 40px #00000029;

    @media (min-width: $grid-min-width-sm) {
        width:  345px;
        height: 345px;
    }
    
    @media (min-width: $display-width-1024) {
        width:  360px;
        height: 360px;
    }
}

.lp-testimonials {
    margin-top: 180px;
}

.testimonials {
    position: relative;
    padding-bottom: 50px;
    background: linear-gradient(to bottom, #9672FB, $color-blue);
    padding-top: 90px;
    border-radius: 20px;
    
    @media (min-width: $grid-min-width-sm) {
        padding-top: 0;
        background: transparent;
        border-radius: 0;
    }
}

.testimonials-background {
    @media (min-width: $grid-min-width-sm) {
        background: linear-gradient(to bottom, #9672FB, $color-blue);
        width: 100%;
        height: 430px;
        border-radius: 20px;
        position: absolute;
        top: 0;
        left:0;
        z-index: -1;
    }
}

.testimonials-header {
    width: 100%;
    color: $color-purple;
    opacity: 0.2;
    font-size: 46px;
    font-weight: $font-weight-bold;
    line-height: 32px;
    
    @media (min-width: $grid-min-width-sm) {
        font-size: 100px;
        line-height: 68px;
    }

}

.testimonials-slider {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    
    @media (min-width: $grid-min-width-md) {
        grid-template-columns: 100px 1fr 100px;
    }

}

.slider__arrow {
    
    @media (min-width: $grid-min-width-sm) {
        width: 40px;
    }

   img {
       display: none;
       @media (min-width: $grid-min-width-md) {
            height: 80px;
        margin-top: calc((#{$testimonials-height} - 80px) / 2);
        cursor: pointer;
        display: block;
    }
    }   
}

.slider__wrapper {
    width:    100%;
    height:   auto;
    overflow: scroll;
    padding-left:45px;
    display: inline-flex;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }

    @media (min-width: $grid-min-width-sm) {
        padding: 0;
    }
    @media (min-width: $grid-min-width-md) {
        overflow: hidden;
    }
}

.slider {
    display: grid;
    grid-template-columns: 1fr 1fr;
    transition: all 0.5s;
    grid-column-gap: 20px;

    @media (min-width: $grid-min-width-sm) {
        grid-column-gap: 0;
    }

}

.slider-item {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    justify-items: center;

    &:last-child {
        margin-right: 45px;
    }
    
    @media (min-width: $grid-min-width-sm) {
        grid-template-columns: auto 1fr;
        justify-items: flex-start;
        &:last-child {
            margin-right: 0;
        }
    }

    @media (min-width: $grid-min-width-md) {
        grid-template-columns: 1fr 1fr;
    }
}

.slider-item__video-wrapper {
    position: relative;
    width: fit-content;
    border-radius: 20px;
    overflow: hidden;
    width: 280px;
    height: 0px;
    padding-top: 500px;
    
    @media (min-width: $grid-min-width-sm) {
        margin-top:    25px;
        width: 320px;
        width: fit-content;
        height: auto;
        padding: 0;
    }
}

.slider-item__video {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 20px;
    
    @media (min-width: $grid-min-width-sm) {
        width: auto;
        position: relative;
    }
}

.slider-video__control-wrapper {
    background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(23, 156, 222, 1));
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    top: 0;
    border-radius: 20px;
}

.slider-video__control {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    z-index: 2;
    pointer-events: none;
}

.slider-video__actor {
    display: flex;
    align-items: center;
    position: absolute;
    left: 20px;
    bottom: 20px;

    pointer-events: none;

    .slider-item__image {
        margin: 0;
    }

    .slider-item__name {
        margin: 0 0 0 10px;
    }
}

.slider-item__text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    @media (min-width: $grid-min-width-sm) {
        height: $testimonials-height;
        padding: 0 40px;
    }
    
    @media (min-width: $grid-min-width-md) {
        padding: 0;
    }
}

.slider-item__text {
    font-family: $font-family-base-condensed;
    font-style: italic;
    font-size: 22px;
    color: $color-fg;
    margin-top: 50px;
    
    @media (min-width: $grid-min-width-sm) {
        margin-top: 0;
    }
   
    @media (min-width: $grid-min-width-md) {
        font-size: 30px;
    }
}

.slider-item__image {
    width: 50px;
    height: 50px;
    border: 2px solid #fff;
    border-radius: 50%;
    background-size: contain;
    margin-top: 30px;
}

.slider-item__name {
    font-size: 16px;
    color: $color-fg;
    margin-top: 10px;
}

.slider-dots {
    position: absolute;
    left: 50%;
    bottom: -30px;
    transform: translateX(-50%);

    @media (min-width: $grid-min-width-sm) {
        bottom: 0;
    }

}

.slider-dot {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    cursor: pointer;

    &:last-child {
        margin: 0;
    }
}

.contact {
    margin-top: 120px;

    &.contact--add-on {
        margin-top: 60px;
    }
}

.constact__add-on {
    margin-bottom: 100px;
    font-size: 20px;
    color: #666;
}

.contact__headline {
    position: relative;
    font-weight: $font-weight-bolder;
    font-size: 20px;
    font-style: italic;
}

.contact__headline-background {
    position: absolute;
    height: auto;
    z-index: -1;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -15px);
    width: 98vw;
    
    @media (min-width: $grid-min-width-sm) {
        width: 450px;
    }
}

.contact__form-item__wrapper {
    display: grid;
    grid-column-gap: 30px;
    width: 100%;
    max-width: 690px;
    grid-template-columns: 1fr;
    
    @media (min-width: $grid-min-width-sm) {
        grid-template-columns: 1fr 1fr;
    }
}

.contact__form {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @media (min-width: $grid-min-width-sm) {
        align-items: center;
        margin-top: 50px;
        padding: 0;
    }
}

.contact__form-item {
    max-width: 690px;
    margin-top: 25px;
    width: 100%;
}

.contact__form-label{
    display: flex;
    flex-direction: column;
    font-size: 13px;
    align-items: flex-start;

    &.contact__form-label--checkbox {
        margin-top: 20px;

        span {
            text-align: left;

            @media (min-width: $grid-min-width-sm) {
                text-align: center;
            }
        }
    }
}

.contact__form-checkbox-wrapper {
    display: flex;
    align-items: center;
}

.contact__form-label.contact__form-label--error {
    .contact__form-checkbox-clone,
    .contact__form-input {
        border-color: $color-highlight;

        &::placeholder {
            color: $color-highlight;
        }
    }

    .contact__form-error {
        display: block;
    }
}

.contact__form-input {
    width: 100%;
    height: 65px;
    padding-left: 20px;
    border-radius: 10px;
    border: 1px solid $color-blue;
    color: $color-blue;
    font-size: 15px;
    margin-top: 10px;

    &.contact__form-input--textarea {
        width: 100%;
        padding: 20px;
        font-family: $font-family-base;
        height: 125px;
    }
    
    &::placeholder {
        font-size: 15px;
        color: $color-blue;
    }

    &.contact__form-input--checkbox {
        width: 26px;
        height: 26px;
        border: none;
        box-shadow: none;
        border: 0;
        margin-top: 0;
        position: absolute;
        opacity: 0;
    }

    &.contact__form-input--submit{
        background-color: $color-blue;
        color: $color-fg;
        height: 55px;
        border-radius: 0;
        cursor: pointer;
        -webkit-appearance: none;
     }
}

.contact__form-input--submit-wrapper {
    position: fixed;
    bottom:    50px;
    left:      0;
    width:     100%;
    z-index:   100;

    .contact__form-input--submit {
        padding-right: 20px;
        width: auto;
    }
    
    @media (min-width: $grid-min-width-sm) {
        margin-top: 30px;
        width: 315px;
        position: relative;
        bottom: auto;
        left:   auto;
        .contact__form-input--submit {
            padding-right: 20px;
            width: 315px;
        }
    }
}

.contact__form-error {
    display: none;
    color: $color-highlight;
    margin-top: 5px;
}

.contact__form-checkbox-clone {
       width: 26px;
       height: 26px;
       border: 1px solid $color-blue;
       border-radius: 2px;
       top: 2px;
       border-radius: 5px;
       margin-right: 10px;
}

input:checked ~ .contact__form-checkbox-clone {
    background-color: $color-blue;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: url('../images/check_white.svg');
        background-size: cover;
 }
}

.modal__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background:rgba(0,0,0,.3);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.modal {
    width: 100%;
    height: auto;
    border-radius: 20px;
    background-color: $color-fg;
    padding: 30px 50px 50px 50px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    @media (min-width: $grid-min-width-sm) {
        width: fit-content;
        max-width: 90%;
    }
}

.modal__check {
    height: 50px;
}

.modal__headline {
    font-size: 22px;
    font-style: italic;
    font-family: $font-family-base-condensed;
    font-weight: $font-weight-bolder;
    margin-top: 20px;
}

.modal__subline {
    font-size: 15px;
    font-style: italic;
    margin-top: 20px;
}

.landingpage .footer__meta {
    margin-top: 100px;
}