@import "./Fonts.css";
@import "./index.scss";

  
.terms {
	text-align: left;
	font-size: 16px;

	display:        flex;
    flex-direction: column;
    padding:        0 10px;
    margin:         0 auto;
    min-height:      100vh;
    padding-bottom:  30px;
	position:        relative;
}

.terms.dark {
	color:            $color-fg;
	background-color: $color-bg;
}

.terms.light {
	color:            $color-bg;
	background-color: $color-fg;
}

.static__container {
	font-size:     1.4rem;
	margin-bottom: 25px;
	margin-left:   $mobile-content-padding;
	margin-right:  $mobile-content-padding;
	margin-top:    25px;

	& * {
		font-size: 12px;
	}
	
	& a {
		font-size: 1rem;
	}
}

.static__text--info {
	font-size:  1.2rem;
	margin-top: 20px;
}

.static__block--page,
.static__block--section,
.static__block--main,
.static__block--sub {
	margin-bottom:  10px;
	text-transform: uppercase;
	width:          100%;
}

.static__block--page {
	font-Size:  1.5rem;
	margin-top: 10px;
	word-break: break-word;
}

.static__block--section {
	font-size:  2rem;
	margin-top: 50px;
}

.static__block--main {
	font-size:  1.8rem;
	margin-top: 30px;
}

.static__block--sub {
	font-size:  1.4rem;
	margin-top: 20px;
}

.static__block--text {
	font-size:  1.4rem;
	margin-top: 15px;
	width:      100%;
}

.static__block--sub.-no-margin-bottom {
	margin-bottom: 0px;
}

.static__block--sub.-no-margin-bottom + .static__block--text {
	margin-top: 0px;
}

.static__number,
.static__headline,
.static__text {
	display:        inline-block;
	vertical-align: top;
}

.static__number {
	width: 40px;
}

.static__text {
	width: calc(100% - 40px);
	&.-bold {
		font-weight: $font-weight-bold;
	}
}

.static__link {
	color: $color-mood-2;
}

.static__list {
	padding-left:   17px;
	padding-bottom: 0;
	margin-bottom:  0;

	li {
		padding-left:   15px;
		padding-bottom: 15px;
	}

	li:last-child {
		padding-bottom: 0;
	}
}

.static__block--support {
	display:         flex;
	flex-direction:  row;
	flex-wrap:       wrap;
	justify-content: space-around;
	margin-top:      20px;
}

.static__country-container {
	flex-grow:      0;
	flex-shrink:    0;
	margin-bottom:  10px;
	margin-top:     10px;
	min-width:      160px;
	vertical-align: middle;
}

.static__country-container.-empty {
	height: 0px;
	margin-bottom: 0px;
	margin-top: 0px;
}

.static__flag,
.static__country-data {
	display:        inline-block;
	vertical-align: top;
}

.static__country-data {
	margin-left: 10px;
}

.static__country,
.static__phone,
.static__mail {
	display: block;
}

.static__country {
	text-transform: uppercase;
}

// Whatsapp subscription
.guest-whatsapp__container {
	padding:    0 40px 0 40px;
	text-align: center;

	.guest-whatsapp__headline {
		font-weight:   $font-weight-normal;
		font-size:     2.1rem;
		margin-left:   60px;
		margin-bottom: 30px;
		display:       inline-block;
		text-align:    left;
		position:      relative;

		&:before {
			background-size: 50px;
			height:          50px;
			left:            -60px;
			position:        absolute;
			top:             0;
			width:           50px;
		}
	}
}

.guest-whatsapp__phone-number {
	color:       $color-success;
	white-space: nowrap;
}

.guest-whatsapp__steps-container {
	margin:  0 0 5px 0;
	padding: 0 0 0 20px;
	display: inline-block;
}

.guest-whatsapp__steps {
	font-size:  $font-size-md;
	margin:     0 0 15px 0;
	text-align: left;
}

.guest-whatsapp__data-protection-notice {
	margin-top:   20px;
	padding-left: 10px;
	text-align:   left;
}

.guest-whatsapp__data-protection-notice__link {
	color:           inherit;
	text-decoration: underline;
}

.content-box.-padding-deep-help {
	padding: $mobile-content-padding;
}

.text__headline {
	font-size: 1.5rem;
}

.text__itemnumber {
	margin-left:  $mobile-content-padding;
	margin-right: $mobile-content-padding;
}

.text__itemtext {
	width: calc(100% - 55px);
}
	

.text__headline--section {
	padding-bottom: 17px;
	font-weight:    $font-weight-normal;
	font-size:      1.2rem;
}

.text__headline__subheadline {
	font-size: 2rem;
}

.text__headline--highlight {
	@extend .text__headline;
	padding-bottom: 25px;
	color:          $color-highlight
}

.text__headline--highlight__subheadline {
	color:     $color-fg;
	font-size: 2rem;
}

.text__headline--item {
	@extend .text__headline;
	font-size: 1.4rem;
}

.text__introtext {
	font-size:      1.5rem;
	font-weight:    $font-weight-normal;
	padding-bottom: 20px;
	clear:          both;
}

.text__section {
	font-size:      1rem;
	padding-bottom: 20px;
	clear:          both;

	&.-bold {
		font-weight: $font-weight-bold;
	}
}

.text__section-headline {
	font-size: 1rem;
	font-weight: bold;
	margin-bottom: 5px;
}

.text__section-ol {
		counter-reset: list;
		margin-top: 10px;
		margin-bottom: 10px;
		list-style: none;
	
	li {
		list-style: none;
		position: relative;
		font-size: 1rem;
	}
	
	li:before {
		counter-increment: list;
		content: "(" counter(list, numbers) ") ";
		padding-right: 2px;
		left: -1.4em;
	}
}

.text__itemlist {
	&:after {
		clear:   both;
		content: ' ';
		display: block;
	}
}

.text__itemnumber {
	font-size:    1.5rem;
	float:        left;
	margin-left:  36px;
	margin-right: 25px;

	&.-no-margin-left {
		margin-left: 0;
	}
	&.-not-shown {
		visibility: hidden;
	}
	&.-text-uppercase {
		text-transform: uppercase;
		font-size:      1.9rem;
	}
	&.-margin-top-big {
		margin-top: 15px;
	}
	&.-padding-bottom-higher {
		padding-bottom: 25px;
	}
	&.-hide-number {
		visibility: hidden;
	}
}

.text__itemtext {
	float:          left;
	margin-right:   auto;
	padding-bottom: 21px;
	width:          calc(100% - 90px);
	font-size:      1.5rem;

	&.-text-uppercase {
		text-transform: uppercase;
		font-size:      1.9rem;
	}
	&.-margin-top-big {
		margin-top: 15px;
	}
	&.-padding-bottom-none {
		padding-bottom: 0;
	}
	&.-padding-bottom-higher {
		padding-bottom: 25px;
	}
}

.itemtext__link {
	font-size: 1rem;
	&:visited, &:active, &:link {
		color: $color-mood-2;
	}
	&:hover {
		color: $color-mood-1;
	}
}

.text__ordered-list {
	@extend .h-ordered-list !optional;
	padding-left:   17px;
	padding-bottom: 0;
	margin-bottom:  0;
	li {
		padding-left:   15px;
		padding-bottom: 15px;
	}
	li:last-child {
		padding-bottom: 0;
	}
}

.h-color-highlight {
	color: #FF4D3C;
	cursor: pointer;
}

.terms-md {
	h1 {
		top: 0;
		font-size: 2rem;
		padding: 0;

		.dark & {
			color:            $color-fg;

		}
	}

	h2 {
		font-size: 1.5rem;
		margin-top: 10px;
	}
	h3 {
		font-size: 1rem;
		margin-top: 10px;
		margin-bottom: 5px;
	}

	ul, ol {
		list-style-position: outside;
		padding-left:        15px;
		margin:              5px 0;
	}

	a {
		font-size: 12px;
	}
}