@import "../Css/globals.scss";

$color-white-rgb:       255, 255, 255;
$color-black-rgb:       0, 0, 0;
$color-dark:            #333;
$color-darker:          #202020;
$color-light-darker:    #262728;
$color-light:           #ddd;
$color-lightgrey:       #cccccc;
$color-lightgrey2:      #f1f1f1;
$color-grey:            #888888;
$color-blue:            #179cde;
$color-lightblue:       #37aee2;
$color-green:           #43B31C;
$color-red:             #FF4D3C;
$color-gold:            #EEC126;

.telegram-widget-container:after {
	clear: both;
}

.telegram-widget-container {
	max-width:  600px;
	margin-top: 400px;
}

.telegram-widget-wrapper {
	border: 1px solid #cccccc;
}

.telegram-widget-container:before,
.telegram-widget-container:after,
.grid-100:after,
.mobile-grid-100:after,
.tablet-grid-100:after {
	content:     ".";
	display:     block;
	overflow:    hidden;
	visibility:  hidden;
	font-size:   0;
	line-height: 0;
	width:       0;
	height:      0;
}

.clear {
	clear:      both;
	display:    block;
	overflow:   hidden;
	visibility: hidden;
	width:      0;
	height:     0;
}

.grid-100,
.mobile-grid-100,
.tablet-grid-100 {
	-moz-box-sizing:    border-box;
	-webkit-box-sizing: border-box;
	box-sizing:         border-box;
}

.grid-100 {
	clear: both;
	width: 100%;
}

/***Grid***/

body {
	font-family:              'Roboto', sans-serif;
	font-style:               normal;
	font-weight:              400;
	background-color:         #ffffff;
	text-align:               center;
	font-size:                100%;
	color:                    $color-bg;
	-webkit-font-smoothing:   antialiased;
	-webkit-text-size-adjust: 100%;
	margin:                   0;
	padding:                  0;
}

.page {
	position: absolute;
	top:      0;
	left:     0;
	z-index:  2;
	width:    100vw;
	height:   100vh;
	overflow: auto;
	padding:  0 0 0 65px;
}

.site__wrapper {
	min-height: 100vh;
	&.light{
		background-color: $color-fg;
	}
	
	&.dark{
		background-color: $color-darker;
	}
}

.bg {
	position:          absolute;
	top:               0;
	left:              0;
	width:             100vw;
	height:            100vh;
	z-index:           1;
	background-size:   100%;
	background-repeat: no-repeat;
}

.bg.dark {
	background-color: #0e0e0e;
}

a {
	color:           $color-blue;
	text-decoration: none;
	cursor:          pointer;
}

.pointer {
	cursor: pointer;
}

/***Header***/
header {
	background-color: $color-lightgrey;
	padding:          8px;
	margin:           0 auto;
	display:          flex;
}

.telegram-widget-headtitle {
	display:               grid;
	grid-template-columns: 40px auto;
	margin:                0 auto;
	align-items:           center;
	column-gap:            10px;
}

.telegram-widget-headtitle span {
	font-weight: 800;
}

img.logowidth {
	width:     100%;
	max-width: 40px;
}

h1 {
	font-family:    'Roboto', sans-serif;
	font-size:      16px;
	color:          $color-bg;
	margin:         0;
	font-weight:    600;
	padding-left:   14px;
	position:       relative;
	top:            10px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}

.links-container {
	text-align:   left;
	padding-top:  150px;
	padding-left: 70px;
}

.links-container.dark {
	color: #fff;
}

/***Header***/

/***Content***/

.telegram-widget-content {
	background-color: $color-fg;
	margin:           0 auto;
	font-size:        16px;
	padding:          10px
}

.telegram-widget-content ol {
	display:             block;
	list-style-type:     decimal;
	list-style-position: inside;
	margin:              20px 0 30px;
	text-align:          left;
	line-height:         23px;
}

.telegram-widget-content ol li {
	padding: 5px;
}

.telegram-widget-content-li-wrapper {
	display:        inline-block;
	width:          90%;
	vertical-align: top;
}

.telegram-widget-info {
	margin:      0;
	font-weight: 700;
}

.telegram-widget-disclaimer {
	font-size:   12px;
	color:       $color-grey;
	padding-top: 40px;
}

#agb-checkbox {
	margin-right: 5px;
}

.telegram-widget-disclaimer a {
	color: $color-blue;
}

.telegram-widget-disclaimer a:hover {
	color: $color-lightblue;
}

.telegram-widget-ctabtn {
	padding: 15px 0 30px;
}

button {
	cursor: pointer;
}

.telegram-widget-ctabtn-blue {
	background-color:   $color-blue;
	filter:             progid:DXImageTransform.Microsoft.gradient(startColorstr='#670d10', endColorstr='#5d0c0f',GradientType=0);
	color:              $color-fg;
	-o-transition:      all 0.5s linear;
	-moz-transition:    all 0.5s linear;
	-khtml-transition:  all 0.5s linear;
	-webkit-transition: all 0.5s linear;
	-ms-transition:     all 0.5s linear;
	transition:         all 0.5s linear;
	padding:            10px;
	border:             none;
	border-radius:      2px;
	font-family:        'Roboto', sans-serif;
	font-size:          16px;
	font-weight:        600;
	width:              80%;
	text-align:         center;
	display:            inline-block;
}

.telegram-widget-ctabtn-blue:hover {
	background-color: $color-lightblue;
}

.logo {
	width:      45%;
	position:   absolute;
	top:        100px;
	left:       65px;
	max-width:  600px;
	max-height: 400px;
}

body {
	background-position: top right;
}


/***Content***/

/***Media***/
@media only screen and (max-width: 800px) {
	.page {
		padding: 0 30px;
	}

	.telegram-widget-container {
		max-width:  600px;
		margin-top: 200px;
	}

	.logo {
		width: 45%;
		top:   50px;
		left:  40px;
	}

	.telegram-widget-content ol {
		list-style-position: outside;
		padding-left:        30px;
	}

}

@media only screen and (max-width: 600px) {
	.page {
		padding: 0 10px;
	}

	.telegram-widget-container {
		max-width:  600px;
		margin-top: 200px;
	}

	.telegram-widget-content ol {
		list-style-position: outside;
		padding-left:        30px;
	}

	.logo {
		width:      50%;
		position:   absolute;
		text-align: center;
		top:        50px;
		left:       20px;
	}
}

@media only screen and (max-width: 430px) {

	header {
		display: none;
	}

	.telegram-widget-ctabtn-blue {
		padding: 10px 20px;
	}

	.telegram-widget-container {
		margin-top: 150px;
	}

	.telegram-widget-headtitle {
		display:        block;
		padding-bottom: 15px;
	}

	h1 {
		padding-left: 0;
		font-size:    15px;
	}

	.links-container {
		padding-top:  20px;
		padding-left: 20px;
	}
}

.footer {
	padding-bottom: 50px;
	flex-direction: column;
	padding-top:    50px;
	align-items:    center;
	max-width:      max-content;
	display:        flex;
	margin:         0 auto;
	.light &{
		color: $color-bg;
	}
	
	.dark &{
		color: $color-fg;
	}
}

.footer__logo {
	max-width: 50px;
	height:    auto;
}

.footer__headline {
	text-transform: uppercase;
	font-family:    $font-family-base-condensed;
	font-size:      30px;
	margin:         25px 0 15px;
	.light &{
		color: $color-bg;
	}
	
	.dark &{
		color: $color-fg;
	};
}

.footer__button-wrapper {
	grid-template-columns: 1fr;
	display:               grid;
	margin-bottom:         45px;

	@media (min-width: 992px) {
		grid-template-columns: 1fr 1fr;
		grid-gap:              20px;
	}
}

.footer__button {
	justify-content: center;
	border-radius:   25px;
	margin-top:      20px;
	min-width:       250px;
	display:         inline-flex;
	padding:         10px 20px;
	cursor:          pointer;
}

body .light .footer__button {
	background-color: $color-bg;
	color:            $color-fg;
}

body .dark .footer__button {
	background-color: $color-fg;
	color:            $color-bg;
}

.footer__button:hover {
	.light &{
		background-color: $color-dark;
	}
	
	.dark &{
		background-color: $color-light;
	}
}

.footer__button--highlight {
	margin-right: 5px;
	color:        $color-lightblue;
}

.icon {
	.light &{
		background-color: $color-fg;
	}
	
	.dark &{
		background-color: $color-darker;
	}
	margin-right:        5px;
	-webkit-mask-repeat: no-repeat;
	-webkit-mask-size:   contain;
	mask-size:           contain;
	mask-repeat:         no-repeat;
	height:              20px;
	width:               20px;
	display:             table;
}

.icon--mr0 {
	margin-right: 0;
}

.icon--mr10 {
	margin-right: 10px;
}

.icon--xs {
	width:  14px;
	height: 14px;
}

.icon--sm {
	width:  32px;
	height: 32px;
}

.icon--md {
	width:  50px;
	height: 50px;
}

.icon--lg {
	width:  70px;
	height: 70px;
}

.icon--android {
	-webkit-mask-image: url("../images/android.svg");
}

.icon--apple {
	-webkit-mask-image: url("../images/apple.svg");
}

.icon--keylock {
	-webkit-mask-image: url("../images/keylock_close.svg");
}

.icon--cart {
	-webkit-mask-image: url("../images/cart-full.svg");
}

.icon--check {
	background-color:   $color-green;
	-webkit-mask-image: url("../images/round-success-full.svg");
}

.icon--cancel {
	background-color:   $color-red;
	-webkit-mask-image: url("../images/cancel.svg");
	
	.dark & {
		background-color:   $color-red;
	}
}

.icon--user {
	-webkit-mask-image: url("../images/user-full.svg");

	.light &{
		background-color: $color-bg;
	}
	
	.dark &{
		background-color: $color-fg;
	}
}

.icon--user--inv {
	.light &{
		background-color: $color-fg;
	}
	
	.dark &{
		background-color: $color-darker;
	}
	-webkit-mask-image: url("../images/user-full.svg");
}

.icon.icon--user--grey {
	background-color:   $color-grey;
	-webkit-mask-image: url("../images/user-full.svg");
}

.icon--send {
	.light &{
		background-color: $color-bg;
	}
	
	.dark &{
		background-color: $color-fg;
	}
	-webkit-mask-image: url("../images/send.svg");
}

.icon--send--inv {
	.light &{
		background-color: $color-fg;
	}
	
	.dark &{
		background-color: $color-darker;
	}
	-webkit-mask-image: url("../images/send.svg");
}

.icon--login {
	.light &{
		background-color: $color-bg;
	}
	
	.dark &{
		background-color: $color-fg;
	}
	-webkit-mask-image: url("../images/login.svg");
}

.icon--login--inv {
	.light &{
		background-color: $color-fg;
	}
	
	.dark &{
		background-color: $color-darker;
	}
	-webkit-mask-image: url("../images/login.svg");
}

.icon--signup {
	.light &{
		background-color: $color-bg;
	}
	
	.dark &{
		background-color: $color-fg;
	}
	-webkit-mask-image: url("../images/signup.svg");
}

.icon--signup--inv {
	.light &{
		background-color: $color-fg;
	}
	
	.dark &{
		background-color: $color-darker;
	}
	-webkit-mask-image: url("../images/signup.svg");
}

.icon--logout {
	.light &{
		background-color: $color-bg;
	}
	
	.dark &{
		background-color: $color-fg;
	}
	-webkit-mask-image: url("../images/logout.svg");
}

.icon--logout--inv {
	.light &{
		background-color: $color-fg;
	}
	
	.dark &{
		background-color: $color-darker;
	}
	-webkit-mask-image: url("../images/logout.svg");
}

.icon--star {
	.light &{
		background-color: $color-bg;
	}
	
	.dark &{
		background-color: $color-fg;
	}
	-webkit-mask-image: url("../images/star.svg");
}

.icon--star--inv {
	.light &{
		background-color: $color-fg;
	}
	
	.dark &{
		background-color: $color-darker;
	}
	-webkit-mask-image: url("../images/star.svg");
}

.icon--arrow-right {
	-webkit-mask-image: url("../images/arrow-right--white.svg");
}

.icon--arrow-left {
	-webkit-mask-image: url("../images/arrow-left--white.svg");
}

.icon.icon--visibility {
	-webkit-mask-image: url("../images/visibility.svg");
	background-color:   $color-grey;
}

.icon.icon--visibility-off {
	background-color:   $color-grey;
	-webkit-mask-image: url("../images/visibility_off.svg");
}

.icon--menu {
	.light &{
		background-color: $color-bg;
	}
	
	.dark &{
		background-color: $color-fg;
	}
	-webkit-mask-image: url("../images/menu.svg");
}

.icon--menu-open {
	.light &{
		background-color: $color-bg;
	}
	
	.dark &{
		background-color: $color-fg;
	}
	-webkit-mask-image: url("../images/menu_open.svg");
}

.icon--close {
	.light &{
		background-color: $color-bg;
	}
	
	.dark &{
		background-color: $color-fg;
	}
	-webkit-mask-image: url("../images/close.svg");
}

.icon--close--inv {
	.light &{
		background-color: $color-fg;
	}
	
	.dark &{
		background-color: $color-darker;
	}
	-webkit-mask-image: url("../images/close.svg");
}

.icon--check {
	.light &{
		background-color: $color-bg;
	}
	
	.dark &{
		background-color: $color-fg;
	}
	-webkit-mask-image: url("../images/check.svg");
}

.icon--check--inv {
	.light &{
		background-color: $color-fg;
	}
	
	.dark &{
		background-color: $color-darker;
	}
	-webkit-mask-image: url("../images/check.svg");
}

.icon.icon--info {
	background-color:   $color-grey;
	-webkit-mask-image: url("../images/info.svg");
}

.icon.icon--blue {
	background-color: $color-blue;
}

.icon.icon--white {
	background-color: $color-fg;
}

.icon--sm-left {
	width:                 12px;
	-webkit-mask-size:     auto 100%;
	-webkit-mask-position: center;
	height:                32px;
}

.icon--mt-0 {
	margin: 0;
}

.footer__meta {
	margin:  30px 0 10px;
	display: inline-flex;
}

.footer__meta-item {
	font-size: 16px;
	color:     $color-grey;
}

.footer__meta-item:hover {
	color: $color-blue;
}

.footer__meta-item:after {
	content: "|";
	margin:  0 10px;
}

.footer__meta-item:after:hover {
	color: $color-grey;
}

.footer__meta-item:last-child:after {
	content: "";
	margin:  0;
}

.page .footer {
	margin-top:  100px;
	padding-top: 0;
}

.page .footer .footer__meta-item {
	color: $color-lightblue;
}

.premium-ad {
	grid-template-columns: 1fr;
	grid-template-areas:   'headline' 'text' 'abos' 'button';
	font-family:           $font-family-base;
	max-width:             992px;
	grid-gap:              30px;
	display:               grid;
	margin:                75px 0 0;
	.light &{
		color: $color-bg;
	}
	
	.dark &{
		color: $color-fg;
	}
}

.premium-ad__headline {
	justify-content: center;
	text-transform:  uppercase;
	flex-direction:  column;
	grid-area:       headline;
	font-size:       30px;
	display:         flex;
}

.premium-ad__headline--bold {
	font-weight: $font-weight-bold;
}

.premium-ad__text {
	line-height: 24px;
	text-align:  left;
	grid-area:   text;
	font-size:   18px;
	padding:     0 20px;
}

.premium-ad__button {
	.light &{
		background-color: $color-bg;
	}
	
	.dark &{
		background-color: $color-fg;
	}
	justify-content:  center;
	border-radius:    2px;
	align-items:      center;
	font-size:        16px;
	grid-area:        button;
	padding:          5px 25px;
	display:          inline-flex;
	margin:           0 auto;
	.light &{
		color: $color-fg;
	}
	
	.dark &{
		color: $color-darker;
	}
}

.premium-ad__button:hover {
	.light &{
		background-color: $color-dark;
	}
	
	.dark &{
		background-color: $color-light;
	}
}

.premium-ad__button-icon {
	.light &{
		background-color: $color-fg;
	}
	
	.dark &{
		background-color: $color-darker;
	}
	-webkit-mask-image: url("../images/arrow-right--blue.svg");
	mask-size:          contain;
	display:            inline-block;
	height:             30px;
	width:              30px;
}

.premium-ad__abo-container {
	grid-template-columns: 1fr 1fr;
	grid-area:             abos;
	grid-gap:              10px;
	display:               grid;
}

.premium-ad__abo-tile {
	flex-direction: column;
	display:        flex;
}

.premium-ad__abo-tile__image-container {
	position: relative
}

.premium-ad__abo-tile__image {
	max-width: 100%;
	height:    auto;
}

.premium-ad__abo-tile__badge {
	background-repeat: no-repeat;
	background-image:  url("../images/defaultbadge.svg");
	background-size:   contain;
	position:          absolute;
	height:            50px;
	width:             50px;
	right:             5px;
	top:               5px;
}

.premium-ad__abo-tile__headline {
	font-weight: $font-weight-bold;
	text-align:  left;
	margin-top:  10px;
	font-size:   16px;
}

.premium-ad__abo-tile__text {
	font-weight: $font-weight-normal;
	text-align:  left;
	font-size:   14px;
}

.premium-ad__abo-tile--first {
	grid-column-start: 1;
	grid-column-end:   3;
}

.premium-ad__abo-tile--first .premium-ad__abo-tile__badge {
	height: 100px;
	width:  100px;
	right:  15px;
	top:    15px;
}

.premium-ad__abo-tile--first .premium-ad__abo-tile__headline {
	font-size: 20px;
}

.premium-ad__abo-tile--first .premium-ad__abo-tile__text {
	font-size: 18px;
}

@media (min-width: 992px) {
	.page .footer {
		margin: 100px 0 0 0;
	}

	.page .footer .footer__meta {
		text-align: left;
		width:      100%;
	}

	.premium-ad {
		grid-template-columns: 1fr auto;
		grid-template-areas:   'headline headline' 'text button' 'abos abos';
	}

	.premium-ad__headline {
		justify-content: flex-start;
		flex-direction:  row;
		font-size:       50px;
	}

	.premium-ad__text {
		padding: 0;
	}

	.premium-ad__headline--bold {
		margin-left: 10px;
	}

	.premium-ad__abo-container {
		grid-template-columns: 1fr 1fr 1fr;
	}

	.premium-ad__abo-tile--first {
		grid-column-start: 1;
		grid-column-end:   2;
	}

	.premium-ad__abo-tile--first .premium-ad__abo-tile__badge,
	.premium-ad__abo-tile__badge {
		height: 100px;
		width:  100px;
		right:  10px;
		top:    10px;
	}

	.premium-ad__abo-tile__headline {
		font-size: 20px;
	}

	.premium-ad__abo-tile__text {
		font-size: 18px;
	}

}


.spinner {
	position:     relative;
	padding:      10px;
	margin-right: 10px;

}

@keyframes spinner {
	to {
		transform: rotate(360deg);
	}
}

.spinner:before {
	content:          '';
	box-sizing:       border-box;
	position:         absolute;
	top:              50%;
	left:             50%;
	width:            20px;
	height:           20px;
	margin-top:       -10px;
	margin-left:      -10px;
	border-radius:    50%;
	border:           2px solid transparent;
	border-top-color: white;
	animation:        spinner .6s linear infinite;
}

.spinner--black:before {
	border-top-color: black;
}

.spinner--big:before {
	width:     90px;
	height:    90px;
	animation: spinner 0.8s linear infinite;
}

.mt-0 {
	margin-top: 0;
}

.mt-25 {
	margin-top: 25px;
}

.mt-30 {
	margin-top: 30px;
}

.mt-90 {
	margin-top: 90px;
}

.navigation-bar {
	display:         flex;
	justify-content: space-between;
	align-items:     center;
	padding:         20px 0 0;
	width:           100%;
}

.navigation-bar--right {
	justify-content: flex-end;
}

.navigation-bar__back-btn {
	font-size:   16px;
	color:       $color-blue;
	display:     flex;
	align-items: center;
	cursor:      pointer;
}

.powered-by {
	font-size:       14px;
	display:         flex;
	align-items:     center;
	justify-content: center;
	width:           100%;
	color:           $color-grey;
	margin-top:      20px;
}

.powered-by__img {
	height:      16px;
	width:       auto;
	margin-left: 5px;
}

.progress-bar__wrapper {
	width:         250px;
	border-radius: 5px;
	border:        1px solid $color-grey;
	height:        10px;
	position:      relative;
	overflow:      hidden;
}

.progress-bar {
	width:            20px;
	height:           6px;
	position:         absolute;
	background-color: $color-blue;
	top:              1px;
	bottom:           1px;
	border-radius:    3px;
	animation:        slide 2s linear infinite;
	left:             -20px;
}

@keyframes slide {
	0% {
		left: 1px;
	}
	100% {
		left: 245px;
	}
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-40 {
	margin-bottom: 30px;
}

.mb-50 {
	margin-bottom: 30px;
}

.mb-60 {
	margin-bottom: 60px;
}

.mt-5 {
	margin-top: 5px;
}

.mt-10 {
	margin-top: 10px;
}

.join-channel-text {
	margin-left: 20px;
	margin-right: 20px;
	margin-top: 20px;
	text-align: center;
}