@import "../Css/index.scss";

.sidebar {
    position: absolute;
    right:    0;
    z-index:  2;
    text-align: center;
}

.sidebar-relative {
    position: relative;
}

.sidebar-button {
    display:  flex;
    position: fixed;    
    right:    20px;
    top:      20px;
    cursor:   pointer;
}

.sidebar-button--navigation-bar {
    position: relative;
    right: auto;
    top: auto;
}

.sidebar-container {
    width:     100%;
    height:    100vh;
    position:  fixed;
    transform: translateX(100%);
    right:     0;
    .light &{
		background-color: $color-fg;
	}
	
	.dark &{
		background-color: $color-dark;
	}
    transition: all 0.5s linear;
    top:      0;
}

@media (min-width: 768px) {
    .sidebar-container {
        width: 50%;
    }
}

@media (min-width: 992px) {
    .sidebar-container {
        width: 290px;
    }
}

.sidebar-container--show {
    transform: translateX(0);
    transition: all 0.5s linear;
    box-shadow: 0px 0px 10px 0px $color-bg;
}

.sidebar-list {
    margin-top:            75px;
    display:               grid;
    grid-template-columns: 1fr ;
    row-gap:               25px;
    padding:               0 25px;
}

.sidebar-list__item {
    font-size: 20px;
    .light &{
		color: $color-bg;
	}
	
	.dark &{
		color: $color-fg;
	}
    cursor:    pointer;
    text-align: left;
    display:    flex;
}

.sidebar-list__item--no-cursor {
    cursor:unset;
}

.sidebar-list__item--active,
.dark .sidebar-list__item--active {
    color: $color-blue;
}

.dark .sidebar-list__item--active .icon,
.sidebar-list__item--active .icon{
    background-color:   $color-blue;
}

.sidebar-list__item--name,
.dark .sidebar-list__item--name {
    font-size: 15px;
    border-bottom: 1px solid $color-lightgrey;
    padding-bottom: 10px;
    color: $color-grey;
    margin-bottom: 20px;
}

.sidebar-list__item--bottom {
    position: absolute;
    bottom: 20px;
}

.sidebar-list__item--theme {
    .light &{
		color: $color-bg;
	}
	
	.dark &{
		color: $color-fg;
	}
}