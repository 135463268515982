@import "../Css/index.scss";

.content__wrapper {
	min-height:      100vh;
	display:         flex;
	flex-direction:  column;
	justify-content: space-between;
	padding-bottom:  30px;
	align-items:     center;
}

.content {
	display:        flex;
	align-items:    center;
	flex-direction: column;
	max-width:      343px;
	padding:        0 10px;
	width:          100%;
	margin:         0 auto;
}

.content--center {
	justify-content: center;
	min-height:      100vh;
}

.content-image {
	background-color: $color-grey;
	width:            100%;
	height:           245px;
	display:          flex;
	justify-content:  center;
	align-items:      center;
	color:            $color-fg;
	flex-direction:   column;
	margin-top:       20px;
	overflow:         hidden;
}

.content-image__img {
	height:     100%;
	width:      100%;
	object-fit: cover;
}

.content-image__text {
	font-size:   21px;
	font-family: $font-family-base;
	width:       160px;
	margin-top:  20px;
}

.content-title {
	text-transform: uppercase;
	font-size:      18px;
	font-weight:    $font-weight-bold;
	font-family:    $font-family-base;
	margin:         20px 0 15px;
	text-align:     left;
	width:          100%;
	.light &{
		color: $color-bg;
	}
	
	.dark &{
		color: $color-fg;
	}
}

.content-description {
	font-size:   16px;
	font-family: $font-family-base;
	text-align:  left;
	width:       100%;
	.light &{
		color: $color-bg;
	}
	
	.dark &{
		color: $color-fg;
	}
}

.content-description p {
	width:         calc(100%);
	white-space:   nowrap;
	text-overflow: ellipsis;
	overflow:      hidden;
}

.content-button {
	display:          inline-flex;
	padding:          0 30px;
	border-color:     transparent;
	border-radius:    2px;
	background-color: $color-blue;
	color:            $color-fg;
	margin-top:       35px;
	font-size:        16px;
	cursor:           pointer;
	align-items:      center;
	min-height:       64px;

}

.content-button:disabled {
	color:            $color-fg;
	background-color: $color-lightgrey;
	cursor:           default;
}

.content-button--fixed {
	position:              fixed;
	bottom:                10px;
	left:                  15px;
	right:                 15px;
	padding:               20px 20px;
	display:               grid;
	grid-template-columns: 1fr auto 1fr;
	align-items:           center;
	justify-items:         end;
}

.content-change-payment {
	font-size:  16px;
	color:      $color-grey;
	margin-top: 20px;
	cursor:     pointer;
}

.content__text {
	font-size:       23px;
	display:         flex;
	justify-content: center;
	align-items:     center;
	.light &{
		color: $color-bg;
	}
	
	.dark &{
		color: $color-fg;
	}
}

.content__subtext {
	font-size:       16px;
	color:           $color-grey;
	display:         flex;
	justify-content: center;
	align-items:     center;
}

.content__subtext--vacant {
	flex-direction: column;

	span {
		color: $color-blue;
		cursor: pointer;
	}
}

.content__text--lg {
	font-size: 30px;
}

.content__logo {
	width:  50px;
	height: auto;
}

.content__info {
	font-size: 16px;
}

.content__info {
	font-size: 15px;
}

.content__info span {
	color: $color-blue;
}

.content__message {
	color: $color-red;
}

.content__text-wrapper {
	opacity: 0;
}

.content__text-wrapper--show {
	opacity:    1;
	transition: 1s linear all;
}

