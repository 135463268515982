.legal-texts {
    padding: 20px;
    width: 100%;
    max-width: 992px;
    margin: 0 auto;

    h1, h2, h3, h4, h5, p {
        color: black;

        .dark & {
            color: white;
        }
    }

    h1 {
        all: initial;
        font-size: 2em;
        font-family: "Roboto";
        margin-bottom: 20px;
    }

    h2 {
        margin-top: 10px;
        margin-bottom: 5px;
    }

    p {
        margin-bottom: 5px;
    }

    ol,
    ul {
        list-style-position: inside;
    }
}